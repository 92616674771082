import React, { useEffect, useState, useRef } from "react";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { COLORS, components, sharedHelper } from "@crewos/shared";

import { useGetAllWorkOrderStatus } from "@crewos/workorders";

import {
  useGetEmployeesSchedules,
  useGetJobSchedules,
} from "../api/Schedules.hooks";

import moment from "moment";

import { DebounceInput } from "react-debounce-input";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import tippy from "tippy.js";

const {
  Loader,
  WeekSelector,
  PeriodTypeSelector,
  CustomPeriodSelector,
  AdvanceTablePagination,
  StatusFilter,
} = components;

const PAGE_SIZE = 10;
const INIT_PAGE = 1;

const TYPE_WEEKLY = "TYPE_WEEKLY";

const WorkOrderDetails = ({ workOrder }) => (
  <ListGroup className="text-body small">
    <ListGroupItem className="px-2 d-flex justify-content-between align-items-center">
      <div className="flex-shrink-0 text-muted">Work Order #: </div>
      <div className="min-width-50 d-flex ms-3 justify-content-end">
        {workOrder.workOrderNumber || "-"}
      </div>
    </ListGroupItem>
    <ListGroupItem className="px-2 d-flex justify-content-between align-items-center">
      <div className="flex-shrink-0 text-muted">Dates: </div>
      <div className="min-width-50 d-flex ms-3 justify-content-end">
        {sharedHelper.formatDateRange(workOrder.start, workOrder.end)}
      </div>
    </ListGroupItem>
    <ListGroupItem className="px-2 d-flex justify-content-between align-items-center">
      <div className="flex-shrink-0 text-muted">Status: </div>
      <div className="min-width-50 d-flex ms-3 justify-content-end">
        {workOrder.workOrderStatus?.name || "-"}
      </div>
    </ListGroupItem>
    <ListGroupItem className="px-2 d-flex justify-content-between align-items-center">
      <div className="flex-shrink-0 text-muted">Customer: </div>
      <div className="min-width-50 d-flex ms-3 justify-content-end">
        {workOrder.customer}
      </div>
    </ListGroupItem>
    <ListGroupItem className="px-2 d-flex justify-content-between align-items-center">
      <div className="flex-shrink-0 text-muted">Location: </div>
      <div className="min-width-50 d-flex ms-3 justify-content-end">
        {workOrder.location}
      </div>
    </ListGroupItem>
  </ListGroup>
);

const Schedule = () => {
  const calendarRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [workOrderStatus, setWorkOrderStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();

  const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [dates, setDates] = useState({
    startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
  });

  const [data, setData] = useState();

  const isJobSchedule = location.pathname.includes("job-schedule");

  const getFunction = isJobSchedule
    ? useGetJobSchedules
    : useGetEmployeesSchedules;

  const {
    data: schedules,
    isLoading: isLoadingSchedules,
    get: getSchedules,
  } = getFunction();

  const {
    data: workOrderStatusData,
    isLoading: isLoadingStatus,
    get: getWorkOrderStatus,
  } = useGetAllWorkOrderStatus();

  const onGoToDetails = (workOrder) => {
    navigate(`/workorders/details/${workOrder.id}`);
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(dates.startDate);
    }
  }, [dates]);

  useEffect(() => {
    if (periodType.value === TYPE_WEEKLY) {
      setDates((dates) => ({
        startDate: moment(dates.startDate)
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        endDate: moment(dates.startDate).endOf("isoWeek").format("YYYY-MM-DD"),
      }));
    }
  }, [periodType]);

  useEffect(() => {
    const parameters = {
      ...dates,
      workOrderStatusId: selectedStatus?.id,
      page: page - 1,
      pageSize: sizePerPage,
    };
    if (location.pathname.includes("supervisors-schedule")) {
      parameters.isLead = true;
    }
    getSchedules(parameters);
  }, [
    dates,
    getSchedules,
    refresh,
    selectedStatus,
    location.pathname,
    page,
    sizePerPage,
  ]);

  useEffect(() => {
    getWorkOrderStatus();
  }, [getWorkOrderStatus]);

  useEffect(() => {
    if (schedules) {
      const events = schedules.data.workOrders.map((workOrder) => {
        const workOrderStatusIndex = workOrderStatus
          ? workOrderStatus.findIndex(
              (s) => s.id === workOrder.workOrderStatus.id
            )
          : -1;
        const { background: backgroundColor, text: textColor } =
          COLORS[workOrderStatusIndex + 1];
        const { extendedProps } = workOrder;

        return {
          ...workOrder,
          backgroundColor,
          textColor,
          borderColor: "white",
          extendedProps: {
            ...extendedProps,
            backgroundColor,
            textColor,
            borderColor: "white",
          },
        };
      });
      setData({
        ...schedules,
        data: {
          ...schedules.data,
          events,
        },
      });
    }
  }, [schedules, workOrderStatus]);

  useEffect(() => {
    if (workOrderStatusData) {
      setWorkOrderStatus(workOrderStatusData);
    }
  }, [workOrderStatusData]);

  const getAdjustedEndDate = (endDate) => {
    return moment(endDate).add(1, "days").format("YYYY-MM-DD");
  };

  let title = "";
  let match = location.pathname.match(/schedules\/(\w+)-schedule/);

  if (match) {
    title = sharedHelper.capitalize(match[1]);
  }

  return (
    <Container fluid className="h-100 schedule-container">
      <div className="w-100 h-100">
        <Card className="mb-3 w-100 box-shadow-none h-100">
          <CardHeader className="pt-2 pb-3 d-flex">
            <div className="text-dark flex-grow-1 d-flex align-items-start flex-column">
              <h2 className="mb-0 ">{title} Schedule</h2>
              {isJobSchedule ? (
                <small className="text-muted">
                  Work orders without customer are not listed
                </small>
              ) : null}
            </div>
          </CardHeader>
          <CardBody className="d-flex flex-column border-radius-default flex-grow-1 overflow-x-auto">
            <div className="d-flex align-items-center justify-content-between mb-3">
              {workOrderStatus ? (
                <div className="d-flex align-items-center me-3 position-relative">
                  <span className="flex-shrink-0">Filter by status:</span>
                  <StatusFilter
                    statuses={workOrderStatus}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                  />
                </div>
              ) : null}
              <div className="d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                  {isLoadingSchedules || isLoadingStatus || !data ? (
                    <div className="min-width-50">
                      <Loader size="sm" className="me-3" />
                    </div>
                  ) : null}
                  <PeriodTypeSelector
                    periodType={periodType}
                    setPeriodType={setPeriodType}
                  />
                  {periodType.value === TYPE_WEEKLY ? (
                    <WeekSelector
                      className="me-3"
                      loading={isLoadingSchedules || isLoadingStatus}
                      weekStart={dates.startDate}
                      setWeekStart={(startDate) => {
                        const endDate = moment(startDate)
                          .endOf("isoWeek")
                          .format("YYYY-MM-DD");
                        setDates({
                          startDate,
                          endDate,
                        });
                      }}
                    />
                  ) : (
                    <CustomPeriodSelector
                      defaultStartDate={dates.startDate}
                      defaultEndDate={dates.endDate}
                      onSubmit={(startDate, endDate) => {
                        setDates({ startDate, endDate });
                      }}
                    />
                  )}
                </div>
                <div className="me-3">
                  <div className="mb-0">
                    <DebounceInput
                      className="search border-0 form-control"
                      style={{ minWidth: 100 }}
                      maxLength={50}
                      minLength={1}
                      debounceTimeout={900}
                      placeholder="Search for.."
                      value={search}
                      onChange={(evt) => {
                        setSearch(evt.target.value);
                      }}
                    />
                  </div>
                </div>
                <Button
                  size="sm"
                  className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  onClick={() => setRefresh((prev) => !prev)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </div>
            {isLoadingSchedules || !data ? (
              <Loader />
            ) : (
              <>
                <FullCalendar
                  key={Date.now()}
                  ref={calendarRef}
                  height="auto"
                  schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    resourceTimelinePlugin,
                  ]}
                  initialView="custom"
                  views={{
                    custom: {
                      type: "resourceTimeline",
                      buttonText: "Custom",
                      visibleRange: {
                        start: dates.startDate,
                        end: getAdjustedEndDate(dates.endDate),
                      },
                    },
                  }}
                  slotDuration={{ days: 1 }}
                  resources={data.data.resources || []}
                  events={data.data.events || []}
                  resourceAreaHeaderContent={
                    location.pathname.includes("job-schedule") ? (
                      <div>
                        Customers{" "}
                        <small className="text-muted ms-1">
                          ({schedules.count})
                        </small>
                      </div>
                    ) : (
                      <div>
                        Employees{" "}
                        <small className="text-muted ms-1">
                          ({schedules.count})
                        </small>
                      </div>
                    )
                  }
                  resourceAreaWidth={200}
                  headerToolbar={false}
                  firstDay={1} // Set Monday as the first day of the week
                  slotLabelFormat={{
                    weekday: "short", // 'Sun'
                    day: "2-digit", // '21'
                    month: "short", // 'Apr'
                  }}
                  eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }}
                  eventClick={(info) => {
                    onGoToDetails(info.event.extendedProps);
                  }}
                  eventDidMount={(info) => {
                    const content = sharedHelper.renderComponentToNode(
                      WorkOrderDetails,
                      {
                        workOrder: info.event.extendedProps,
                      }
                    );
                    tippy(info.el, {
                      content,
                      allowHTML: true,
                      placement: "top",
                      animation: "fade",
                    });
                  }}
                />
                {!schedules.count ? (
                  <div className="text-center text-muted small mt-2">
                    No results
                  </div>
                ) : null}
                <br />
                <AdvanceTablePagination
                  totalCount={schedules.count}
                  pageCount={schedules.totalPages}
                  currentPage={page - 1}
                  onPageChange={(page) => setPage(page)}
                  pageSize={sizePerPage}
                  onPageSizeChange={(sizePerPage) => {
                    setSizePerPage(sizePerPage);
                    setPage(INIT_PAGE);
                  }}
                />
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default Schedule;
