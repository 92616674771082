import React, { useEffect } from "react";

import { data } from "@crewos/shared";

import { Route, Outlet } from "react-router-dom";
import Settings from "../pages/settings/Settings";

const { useAuth, useSidebar, SIDEBAR_ACTIONS } = data;

const PACKAGE = "crews";

// Routes
const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Crews Settings",
    path: ``,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: PACKAGE,
    category: "Crews",
    component: Settings,
    order: 3,
  },
];

const Wrapper = () => {
  const [sidebarContext, setSidebarContext] = useSidebar();
  const [authContext] = useAuth();

  useEffect(() => {
    if (
      !sidebarContext.settingsRoutes.find((route) => route.package === PACKAGE)
    ) {
      setSidebarContext({
        action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
        payload: { routes },
      });
    }
  }, [sidebarContext.settingsRoutes, sidebarContext.routes, setSidebarContext]);

  return !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="settings" element={<Wrapper />}>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
    </Route>
  </React.Fragment>
);

const sidebarRoutes = routes.filter((route) => route.sidebar);

export { Routes as SettingRoutes, sidebarRoutes as settingsSidebarRoutes };
