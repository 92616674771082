import React, { useCallback, useMemo } from "react";
import { faCircleCheck, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faImage, faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInspectionDetail, INSPECTION_DETAIL_ACTIONS } from "../providers/inspectionDetailProvider";
import { sharedHelper } from "@crewos/shared";

const QuestionDetail = ({ question }) => {

  const { dispatch } = useInspectionDetail();

  const answer = useMemo(() => {
    if (question.answers.length > 0) {
      return question.answers[0];
    }
    return null;
  }, [question]);

  const attachments = useMemo(() => {
    return question.attachments || [];
  }, [question]);

  const hasImages = useMemo(() => {
    return attachments.some((attachment) => attachment.type === "IMAGE");
  }, [attachments]);

  const hasNotes = useMemo(() => {
    return attachments.some((attachment) => attachment.type === "NOTE");
  }, [attachments]);

  const handlePreviewImages = useCallback((attachment) => {
    const images = attachments.filter((attachment) => attachment.type === "IMAGE").map((image) => ({
      id: image.id,
      title: image.description || "No description",
      url: image.content,
      selected: image.id === attachment.id,
    }));
    dispatch({ type: INSPECTION_DETAIL_ACTIONS.SET_ATTACHMENTS, payload: images });
    dispatch({ type: INSPECTION_DETAIL_ACTIONS.OPEN_PREVIEW_MODAL });
  }, [attachments, dispatch]);

  return (
    <div className="m-2 box-shadow-lg p-2 border-radius-md">
      <div className="d-flex px-2 justify-content-between">
        <div>
          <span>{question.order}. {question.content}</span>
        </div>
        <div className="d-flex">
          {hasImages && (
            <FontAwesomeIcon
              className="ms-2"
              icon={faImage}
            />
          )}
          {hasNotes && (
            <FontAwesomeIcon
              className="ms-2"
              icon={faNoteSticky}
            />
          )}
          {answer && (
            <FontAwesomeIcon
              className="ms-2 text-success"
              icon={faCircleCheck}
            />
          )}
        </div>
      </div>
      <div>
        {answer && (
          <div key={answer.id} className="d-flex flex-column">
            <div className="fw-semibold px-2">
              {question.questionType.slug === "DATE_FIELD" ? sharedHelper.formatDate(answer.content) : (answer.content || answer.option.content || "-")}
            </div>
          </div>
        )}
        {!answer && (
          <div className="text-muted px-2 d-flex gap-2 align-items-center">
            {question.isRequired && (
              <FontAwesomeIcon
                className="ms-2 text-warning"
                icon={faAsterisk}
              />
            )}
            Pending answer
          </div>
        )}
        {attachments.sort((a, b) => {
          if (a.type === "IMAGE" && b.type === "NOTE") {
            return -1;
          }
          if (a.type === "NOTE" && b.type === "IMAGE") {
            return 1;
          }
          return 0;
        }).map((attachment) => (
          <div key={attachment.id} className="p-2 bg-light my-1 border-radius-md d-flex gap-3">
            {attachment.type === "IMAGE" && (
              <>
                <img src={attachment.content} alt={attachment.name} width="80" height="80" className="border-radius-md cursor-pointer" onClick={() => handlePreviewImages(attachment)} />
                <p>
                  {attachment.description}
                </p>
              </>
            )}
            {attachment.type === "NOTE" && (
              <span>{attachment.content}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuestionDetail;
