import React, { useEffect, useState, forwardRef } from "react";
import moment from "moment";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import {
  useWorkOrderDetails,
  ACTIONS as WORK_ORDER_DETAILS_ACTIONS,
} from "../providers/workOrderDetailsProvider";

const CALENDAR_ITEMS = 365;
const SLIDES_TO_SHOW = 20;
const TRANSITION_SPEED = 500;

const getMonths = (setIndicatorMonths) => {
  const allItems = Array.from(document.getElementsByClassName("slick-slide"));
  const activeItems = Array.from(
    document.getElementsByClassName("slick-active")
  );
  const leftIndex =
    Math.min(...activeItems.map((item) => item.getAttribute("data-index"))) - 1;
  const rightIndex =
    Math.max(...activeItems.map((item) => item.getAttribute("data-index"))) + 1;
  const leftItem = allItems.find(
    (i) => parseInt(i.getAttribute("data-index")) === leftIndex
  );
  const rightItem = allItems.find(
    (i) => parseInt(i.getAttribute("data-index")) === rightIndex
  );
  if (leftItem && rightItem) {
    setIndicatorMonths({
      left: moment(leftItem.firstChild.firstChild.id).format("MMMM"),
      right: moment(rightItem.firstChild.firstChild.id).format("MMMM"),
    });
  }
};

const WorkOrderDateSelectorContainer = forwardRef(({ items }, ref) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [indicatorMonths, setIndicatorMonths] = useState({});

  const NextArrow = () => (
    <div
      onClick={() => ref.current.slickNext()}
      className="cursor-pointer ms-3 pt-4"
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        size="lg"
        className="text-primary rounded-circle"
      />
    </div>
  );

  const PrevArrow = () => (
    <div
      onClick={() => ref.current.slickPrev()}
      className="cursor-pointer me-3 pt-4"
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        size="lg"
        className="text-primary rounded-circle"
      />
    </div>
  );

  const settings = {
    initialSlide: items.findIndex(
      (item) => item.value === workOrderDetails.selectedDate
    ),
    infinite: true,
    arrows: true,
    focusOnSelect: true,
    centerMode: true,
    slidesToShow: SLIDES_TO_SHOW,
    speed: TRANSITION_SPEED,
    className: "center d-flex align-items-center justify-content-between",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: SLIDES_TO_SHOW / 3,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: SLIDES_TO_SHOW / 2,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: SLIDES_TO_SHOW,
        },
      },
    ],
    beforeChange: (current, next) => {
      setTimeout(() => {
        setWorkOrderDetails({
          action: WORK_ORDER_DETAILS_ACTIONS.SELECT_DATE,
          payload: { date: items[next].value },
        });
      }, TRANSITION_SPEED);
    },
  };

  useEffect(() => {
    getMonths(setIndicatorMonths);
  }, [workOrderDetails.selectedDate]);

  return (
    <div className="dates-slider">
      <div className="position-relative w-100 d-flex align-items-center justify-content-between mt-1 mb-n3">
        <div
          style={{ top: "50%", zIndex: 0 }}
          className="w-100 mx-0 border-top position-absolute"
        ></div>
        <div
          className="small text-muted bg-white text-uppercase pe-2"
          style={{ zIndex: 1 }}
        >
          {indicatorMonths.left || ""}
        </div>
        <div
          className="small text-muted bg-white text-uppercase ps-2"
          style={{ zIndex: 1 }}
        >
          {indicatorMonths.right || ""}
        </div>
      </div>
      <Slider ref={ref} {...settings}>
        {items.map((item) => (
          <div
            className="cursor-pointer border mt-4 mb-2 d-flex flex-column align-items-center border-white"
            key={item.value}
            id={item.value}
          >
            <span
              className={`${
                item.isWoDate ? "" : "text-muted border-white"
              } border date rounded-circle px-2 py-1 ${
                item.hasData ? "bg-primary-lighter" : ""
              }`}
            >
              {item.dateNumber}
            </span>
            <small className="text-muted text-uppercase mt1">
              {item.dateLabel}
            </small>
          </div>
        ))}
      </Slider>
    </div>
  );
});

const WorkOrderDateSelector = ({ crewWorkDays }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const startDate = moment(workOrderDetails.workOrder.startDate);
  const items = [
    {
      value: startDate.format("YYYY-MM-DD"),
      hasData: Boolean(crewWorkDays[startDate.format("YYYY-MM-DD")]),
      isWoDate: startDate.isBetween(
        moment(workOrderDetails.workOrder.startDate),
        moment(workOrderDetails.workOrder.endDate),
        "day",
        "[]"
      ),
      dateNumber: startDate.format("DD"),
      dateLabel: startDate.format("ddd"),
    },
  ];

  for (let i = 1; i < CALENDAR_ITEMS; i++) {
    const prevDate = moment(startDate).subtract(i, "days");
    items.unshift({
      value: prevDate.format("YYYY-MM-DD"),
      hasData: Boolean(crewWorkDays[prevDate.format("YYYY-MM-DD")]),
      isWoDate: prevDate.isBetween(
        moment(workOrderDetails.workOrder.startDate),
        moment(workOrderDetails.workOrder.endDate),
        "day",
        "[]"
      ),
      dateNumber: prevDate.format("DD"),
      dateLabel: prevDate.format("ddd"),
    });
    const nextDate = moment(startDate).add(i, "days");
    items.push({
      value: nextDate.format("YYYY-MM-DD"),
      hasData: Boolean(crewWorkDays[nextDate.format("YYYY-MM-DD")]),
      isWoDate: nextDate.isBetween(
        moment(workOrderDetails.workOrder.startDate),
        moment(workOrderDetails.workOrder.endDate),
        "day",
        "[]"
      ),
      dateNumber: nextDate.format("DD"),
      dateLabel: nextDate.format("ddd"),
    });
  }

  return (
    <WorkOrderDateSelectorContainer ref={React.createRef()} items={items} />
  );
};

export default WorkOrderDateSelector;
