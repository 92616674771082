import React from "react";

import { useGetWorkOrders } from "./api/WorkOrders.hooks";
import { useGetAllWorkOrderStatus } from "./api/WorkOrderStatus.hooks";
import { useGetWorkOrderWorkPerformed } from "./api/WorkOrders.hooks";

import { data, sharedHelper } from "@crewos/shared";

import { Outlet, Route } from "react-router-dom";

import WorkOrders from "./pages/WorkOrders";
import WorkOrderDetails from "./pages/WorkOrderDetails";

import { SettingRoutes, settingsSidebarRoutes } from "./routes/SettingsRoutes";

import { faToolbox } from "@fortawesome/free-solid-svg-icons";

import "react-quill/dist/quill.snow.css";

const { useAuth, SIDEBAR_ACTIONS } = data;

const WORKORDERS = "WORKORDERS";

// Routes
const routes = (workOrderStatuses = []) => [
  {
    icon: faToolbox,
    sidebar: true,
    name: "Work Orders",
    path: "/workorders",
    package: "workorders",
    scopes: [],
    entries: [
      {
        path: `:workOrderStatusId`,
        sidebar: false,
        component: WorkOrders,
      },
      {
        path: ``,
        sidebar: false,
        component: WorkOrders,
      },
      {
        path: `all`,
        name: "All",
        sidebar: true,
        component: WorkOrders,
      },
      ...workOrderStatuses.map((status) => ({
        name: `${status.name}`,
        path: `${status.id || ""}`,
        sidebar: true,
        component: WorkOrders,
      })),
    ],
  },
  {
    path: "details/:workOrderId",
    name: "Work Order Details",
    scopes: [],
    component: WorkOrderDetails,
  },
];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "workorders"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Root = () => <Wrapper />;

const Routes = (
  <React.Fragment>
    <Route path="/workorders" element={<Root />}>
      {routes().flatMap((route, index) =>
        route.entries
          ? route.entries.map((entry, index) => (
              <Route
                key={index}
                path={entry.path}
                element={<entry.component />}
              />
            ))
          : [
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />,
            ]
      )}
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

const setWorkOrderSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const workOrderStatus = userData.workOrderStatus || [];
  const sidebarRoutes = routes(workOrderStatus).filter(
    (route) => route.sidebar
  );

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "workorders");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: WORKORDERS, order: 6 },
    });
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

export {
  useGetWorkOrders,
  useGetWorkOrderWorkPerformed,
  useGetAllWorkOrderStatus,
  setWorkOrderSidebarRoutes,
  Routes as WorkOrderRoutes,
};
