import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipItem = (props) => {
  const { position = "top", id, className = "" } = props;

  return (
    <span className={className}>
      <span
        className="d-flex align-items-center cursor-pointer"
        data-tooltip-html={props.title}
        data-tooltip-id={"tooltip-" + id}
        data-tooltip-place={position}
      >
        {props.children}
      </span>
      <Tooltip
        id={"tooltip-" + id}
        effect="solid"
        className="bg-primary-lighter text-dark border"
        opacity={props.opacity || "0.9"}
      />
    </span>
  );
};

export default TooltipItem;
