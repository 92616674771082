import React, { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useBlocker } from "react-router-dom";
import TemplateHeader from "./TemplateHeader";
import Sections from "./Sections";
import Groups from "./Groups";
import {
  ORIGIN_PAGE,
  useInspectionTemplate,
} from "../providers/inspectionTemplateProvider";
import { useGetQuestionTypes, useGetTemplate } from "../api/Inspections.hooks";

const BlockNavigation = ({ isBlocked }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked && currentLocation.pathname !== nextLocation.pathname
  );

  const isOpen = useMemo(() => blocker.state === "blocked", [blocker.state]);

  const handleClose = useCallback(() => {
    blocker.reset();
  }, [blocker]);

  const handleWithOutSave = useCallback(() => {
    blocker.proceed();
  }, [blocker]);

  const handleSave = useCallback(() => {
    blocker.reset();
  }, [blocker]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (!isBlocked) {
        blocker.proceed();
      }
    }
  }, [blocker, isBlocked]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={handleClose} className="border-bottom-0">
        You have unsaved changes
      </ModalHeader>
      <ModalBody>
        <p>
          There are edits and progress from your current session that might get
          lost.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={handleWithOutSave}
        >
          Continue without saving
        </Button>
        <Button color="danger" onClick={handleSave}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FormTemplate = ({ origin, id }) => {
  const {
    state: { questionTypes, hasUnsavedChanges },
    updateQuestionTypes,
    setOrigin,
    setFullData,
    resetFullData,
  } = useInspectionTemplate();
  const { data: questionTypesData, get: getQuestionTypes } =
    useGetQuestionTypes();
  const { data: templateToUpdateData, get: getTemplateToUpdate } =
    useGetTemplate(id);

  const onSave = useCallback(() => {
    getTemplateToUpdate();
  }, [getTemplateToUpdate]);

  useEffect(() => {
    if (templateToUpdateData) {
      setFullData(templateToUpdateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateToUpdateData]);

  useEffect(() => {
    updateQuestionTypes(questionTypesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTypesData]);

  useEffect(() => {
    getQuestionTypes({});
    resetFullData();
    setOrigin(origin);
    if (origin === ORIGIN_PAGE.UPDATE) {
      getTemplateToUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!questionTypes || !questionTypes.length) {
    return null;
  }

  return (
    <div className="inspections inspections-create">
      <BlockNavigation isBlocked={hasUnsavedChanges} />
      <TemplateHeader origin={origin} templateId={id} onSave={onSave} />
      <Row className="m-0">
        <Col xs={3} className="p-0">
          <Sections />
        </Col>
        <Col xs={9} className="p-0">
          <Groups />
        </Col>
      </Row>
    </div>
  );
};

export default FormTemplate;
