import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";

import { createRoutes } from "@crewos/shared/src/helpers/routing";

import { CreateTemplates, EditTemplates, ListTemplates } from "./pages";
import InspectionWrapper from "./components/InspectionWrapper";

const routes = [
  {
    name: "Inspections",
    icon: faFileCircleCheck,
    sidebar: true,
    path: "/inspections",
    package: "inspections",
    component: InspectionWrapper,
    entries: [
      {
        icon: null,
        path: "templates/create",
        name: "New Template",
        component: CreateTemplates,
        sidebar: true,
      },
      {
        icon: null,
        path: "templates/edit/:id",
        name: "Edit Template",
        component: EditTemplates,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates",
        name: "Templates",
        component: ListTemplates,
        sidebar: true,
      },
    ],
  },
];

const Routes = createRoutes(routes);
export { Routes, routes };
