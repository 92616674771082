import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Loader from "./Loader";
import { sharedHelper } from "../helpers/sharedHelper";
import { awsApi } from "../api/awsServices";
import ImageContainer from "./ImageContainer";

const UploadPhotosModal = ({ title, onClose, onSubmit }) => {
  const hiddenFileInputPhoto = useRef(null);

  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState();
  const [, setIsDragging] = useState(false);

  const handleFiles = (selectedFiles) => {
    const filesArray = Array.from(selectedFiles);
    const selectedImages = [];
    const now = Date.now();

    const updatedFiles = filesArray.map(
      (image, index) =>
        new File([image], `${now}_${index}`, {
          type: image.type,
        })
    );

    setImages(updatedFiles);

    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        selectedImages.push(reader.result);
        if (selectedImages.length === filesArray.length) {
          setPreviewImages(selectedImages);
        }
      };
    });
  };

  const handleFileChange = (event) => {
    handleFiles(event.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const appendImageToFormData = (formData, image) => {
    formData.append("files", image);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("bucket", process.env.REACT_APP_IMAGES_BUCKET_NAME);
    images.forEach((image) => appendImageToFormData(formData, image));
    const response = await awsApi.uploadFile(formData);
    return response;
  };

  const handleUploadClick = async () => {
    setLoading(true);
    try {
      const { urls } = await handleUpload();
      await onSaveImages(urls);
    } catch (error) {
      sharedHelper.errorToast(
        error.message || "There was an error with your request."
      );
    } finally {
      setLoading(false);
    }
  };

  const onSaveImages = async (images = []) => {
    for (const key in images) {
      const url = images[key];
      await onSubmit({
        attachmentUrl: url,
        description,
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onClosed={onClose}
      size="xl"
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
    >
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title ? title : `Upload Photo`}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="m-1">
              <FormGroup>
                <Label htmlFor="description">
                  <span>Description</span>
                </Label>
                <input
                  data-testid="description"
                  className="form-control-redesign mb-4"
                  id="description"
                  name="description"
                  rows={4}
                  maxLength={255}
                  placeholder="Enter the description .."
                  type="textarea"
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
              <input
                ref={hiddenFileInputPhoto}
                value=""
                multiple={true}
                type="file"
                accept="image/*"
                className="text-truncate"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                size="sm"
                className="me-2"
                onClick={() => hiddenFileInputPhoto.current.click()}
              >
                <FontAwesomeIcon icon={faUpload} className="me-2" />
                Choose Photos
              </Button>
            </div>
            <div>
              {previewImages.map((image, index) => (
                <ImageContainer
                  key={index}
                  alt={`${index}`}
                  src={image}
                  className="border m-1 w-100 h-auto border-radius-default"
                />
              ))}
            </div>
          </Form>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose} className="text-dark">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleUploadClick}
          type="submit"
          disabled={!images.length}
        >
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadPhotosModal;
