import React, { useEffect } from "react";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import brand from "../../assets/img/logoPrimary.png";
import poweredSidebar from "../../assets/img/powered_sidebar.png";

import { useNavigate } from "react-router-dom";

import {
  useSidebar,
  ACTIONS as SIDEBAR_ACTIONS,
} from "../../providers/sidebarProvider";

import { useAuth, ACTIONS as AUTH_ACTIONS } from "../../providers/authProvider";
import { authApi } from "../../api/authServices";
import SidebarCategory from "./SidebarCategory";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { sharedHelper } from "../../helpers/sharedHelper";

const SETTINGS = "SETTINGS";

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  const logout = () => {
    authApi.logout();
    setAuthContext({
      action: AUTH_ACTIONS.REFRESH,
    });
    navigate("/auth/sign-in");
  };

  const routes = Object.keys(sidebarContext.routes)
    .sort(
      (x, y) => sidebarContext.routes[x].order - sidebarContext.routes[y].order
    )
    .filter((pkg) => pkg !== SETTINGS)
    .flatMap((route) => sidebarContext.routes[route].routes)
    .filter((route) =>
      route.package
        ? authContext.userData?.packages
            .map((p) => p.path)
            .indexOf(route.package) > -1
        : true
    );

  useEffect(() => {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.RESET_ROUTES,
    });
  }, [authContext.userData?.packages, setSidebarContext]);

  const settingsRoutes = (
    authContext.userData
      ? sidebarContext.settingsRoutes.filter(
          (route) =>
            route.sidebar &&
            sharedHelper.routeMeetsRole(
              route,
              authContext.userData.role.userRoleScopes
            )
        ) || []
      : []
  )
    .sort((x, y) => x.order - y.order)
    .reduce((p, c) => {
      if (!p[c.category]) {
        p[c.category] = [];
      }
      p[c.category].push(c);
      return p;
    }, {});

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? "toggled" : ""}`}>
      <div className="sidebar-content py-1">
        <div className="pt-3 pb-1 mb-2 sidebar-brand d-flex flex-column align-items-center justify-content-center px-0">
          <img
            src={brand}
            className="rounded p-2 col-12 bg-primary"
            alt={"CrewOS"}
          />
          <h4 className="mt-2">{process.env.REACT_APP_PROJECT_NAME}</h4>
        </div>
        <SideBarUser />
        <div className="sidebar-nav d-flex flex-column p-4 justify-content-between">
          <div className="d-flex flex-column">
            {routes
              .filter(
                (route) =>
                  route.sidebar &&
                  sharedHelper.routeMeetsRole(
                    route,
                    authContext.userData.role.userRoleScopes
                  )
              )
              .map((route, index) =>
                route.entries ? (
                  <SidebarCategory
                    key={index}
                    name={route.name}
                    icon={route.icon}
                    path={route.path}
                    entries={route.entries}
                  />
                ) : (
                  <SidebarItem
                    className="mb-1"
                    key={index}
                    name={route.name}
                    icon={route.icon}
                    path={route.path}
                  />
                )
              )}
            {Object.keys(settingsRoutes).length ? (
              <SidebarCategory
                name="Settings"
                icon={faCog}
                path="settings"
                entries={settingsRoutes}
              />
            ) : null}
            <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
          </div>
          <div className="d-flex flex-column mx-auto py-2 align-items-end">
            <img
              alt={"CrewOS"}
              src={poweredSidebar}
              style={{ maxWidth: 100 }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
