import React from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "../providers/authProvider";
import { sharedHelper } from "../helpers/sharedHelper";

const PackageWrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "schedules"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

export default PackageWrapper;
