import React, { useEffect, useState } from "react";
import { sharedHelper, data } from "@crewos/shared";

import { Col } from "reactstrap";

const { useWorkOrderDetails, useAuth } = data;

const statusLog = (workOrder, status) => {
  const statusLog = workOrder.workOrderStatusLog.find(
    (log) => log.workOrderStatusId === status.id
  );

  let time = statusLog?.updatedAt || statusLog?.createdAt;

  return (
    <div
      style={{ fontSize: 9 }}
      className={`small mt-1 ${
        workOrder.workOrderStatusId === status.id ? "text-body" : "text-muted"
      }`}
    >
      {time ? sharedHelper.formatDateTime(time) : "-"}
    </div>
  );
};

const isStatusActive = (workOrder, workOrderStatus) =>
  workOrder.workOrderStatusId === workOrderStatus.id;

const WorkOrderStatus = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [authContext] = useAuth();

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const datesStatus = authContext.userData.workOrderStatus
      .filter((wos) => wos.name.length)
      .map((status) => statusLog(workOrderDetails.workOrder, status));
    setDates(datesStatus);
  }, [workOrderDetails.workOrder, authContext.userData.workOrderStatus]);

  return (
    <Col className="d-flex flex-column align-items-center col-12 pt-3 mt-3 border-top px-0">
      <div className="min-width-900 col-12 px-0">
        <div className="justify-content-between mx-0 position-relative d-flex w-100 overflow-x-auto">
          <div
            style={{ top: 14, zIndex: 0 }}
            className="w-100 mx-0 border-top position-absolute"
          ></div>
          {authContext.userData.workOrderStatus
            .filter((wos) => wos.name.length)
            .map((status, i) => (
              <div
                key={status.id}
                style={{ minWidth: 100, maxWidth: 100 }}
                className={`mx-1 d-flex flex-column align-items-${
                  i === 0
                    ? "start"
                    : i === authContext.userData.workOrderStatus?.length - 1
                    ? "end"
                    : "center"
                }`}
              >
                <div
                  style={{ zIndex: 1, minWidth: 100, maxWidth: 100 }}
                  className={`d-block bg-${
                    isStatusActive(workOrderDetails.workOrder, status)
                      ? "primary"
                      : "lighter"
                  } text-${
                    isStatusActive(workOrderDetails.workOrder, status)
                      ? "white"
                      : "dark"
                  } py-1 px-2 rounded small text-truncate text-center`}
                >
                  {status.name}
                </div>
                {dates[i]}
              </div>
            ))}
        </div>
      </div>
    </Col>
  );
};

export default WorkOrderStatus;
