import React, { useCallback } from "react";
import OptionItem from "./OptionItem";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";

const Options = ({ options, questionId }) => {
  const { addOption } = useInspectionTemplate();

  const handleAddOption = useCallback(() => {
    addOption(questionId);
  }, [addOption, questionId]);

  return (
    <div className="options-list">
      {options.map((option) => (
        <OptionItem option={option} onChange={() => null} key={option.id} />
      ))}
      <Row className="add-btn-container m-0">
        <Col className="d-grid p-0" md={7}>
          <Button
            size="sm"
            color="white"
            className="btn-dashed py-2 px-4 justify-content-center font-size-1 rounded-4"
            onClick={handleAddOption}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="width-50"
              size="xl"
            />{" "}
            Add Option
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Options;
