import React from "react";

import { Route, Outlet } from "react-router-dom";
import { BaseRoutes, SettingsWrapper, settingsRoutes } from "./routes/Routes";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Page401 from "./pages/auth/Page401";
import { useGetAllPackages } from "./api/Packages.hooks";
import { data, sharedHelper } from "@crewos/shared";

const { SIDEBAR_ACTIONS, useAuth } = data;

const setCoreSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const packageEnabled = sharedHelper.isPackageEnabled(packages, "workorders");

  const settingsSidebarRoutes = settingsRoutes.filter(
    (settingRoute) => settingRoute.sidebar
  );

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

const Wrapper = () => {
  const [authContext] = useAuth();

  return !authContext.userData ? null : <Outlet />;
};

const SettingRoutes = (
  <React.Fragment>
    <Route path="settings" element={<SettingsWrapper />}>
      {settingsRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
    </Route>
  </React.Fragment>
);

const Routes = (
  <React.Fragment>
    <Route path="/core" element={<Wrapper />}>
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

export {
  Routes as CoreRoutes,
  BaseRoutes,
  setCoreSidebarRoutes,
  useGetAllPackages,
  Page404,
  Page500,
  Page401,
};
