import React from "react";

import { data, sharedHelper } from "@crewos/shared";

import Schedule from "./pages/Schedule";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import { Route, Outlet } from "react-router-dom";

import "./assets/scss/schedules.scss";

const { useAuth, SIDEBAR_ACTIONS } = data;

const SCHEDULES = "SCHEDULES";

// Routes
const routes = [
  {
    name: "Calendar",
    icon: faCalendar,
    sidebar: true,
    path: "schedules",
    package: "schedules",
    entries: [
      {
        icon: null,
        name: "Job Schedule",
        path: `job-schedule`,
        component: Schedule,
        sidebar: true,
      },
      {
        icon: null,
        name: "Supervisor Schedule",
        path: `supervisor-schedule`,
        component: Schedule,
        sidebar: true,
      },
      {
        icon: null,
        name: "Crew Schedule",
        path: `crew-schedule`,
        component: Schedule,
        sidebar: true,
      },
    ],
  },
];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "schedules"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="/schedules" element={<Wrapper />}>
      {routes.flatMap((route, index) =>
        route.entries
          ? route.entries.map((entry, index) => (
              <Route
                key={index}
                path={entry.path}
                element={<entry.component key={index} />}
              />
            ))
          : [
              <Route
                key={index}
                path={route.path}
                element={<route.component key={index} />}
              />,
            ]
      )}
    </Route>
  </React.Fragment>
);

const setScheduleSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "schedules");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: SCHEDULES, order: 6 },
    });
  }
};

export { Routes as ScheduleRoutes, setScheduleSidebarRoutes };
