import React from "react";

import AddTravelTimeModal from "./components/AddTravelTimeModal";
import EditTravelTimeModal from "./components/EditTravelTimeModal";
import CrewWorkDayTravelTimes from "./components/CrewWorkDayTravelTimes";

import { Route, Outlet } from "react-router-dom";

import { data, sharedHelper } from "@crewos/shared";

import { SettingRoutes, settingsSidebarRoutes } from "./routes/SettingsRoutes";

const { useAuth, SIDEBAR_ACTIONS } = data;

const TRAVELTIMES = "TRAVELTIMES";

const routes = [];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "traveltimes"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="traveltimes" element={<Wrapper />}>
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

const setTravelTimeSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "traveltimes");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: TRAVELTIMES, order: 6 },
    });
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

export {
  Routes as TravelTimeRoutes,
  setTravelTimeSidebarRoutes,
  EditTravelTimeModal,
  CrewWorkDayTravelTimes,
  AddTravelTimeModal,
};
