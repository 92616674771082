import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useCreateUser, useUpdateUser } from "../api/Users.hooks";
import { useGetEmployees } from "@crewos/employees";
import { useGetAllUserRoles } from "../api/UserRoles.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

const { useAuth } = data;

const { Loader, ConfirmationModal } = components;

const MAX_PAGE_SIZE = Number.MAX_SAFE_INTEGER;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const UserModal = ({ user = {}, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const [confirmationModal, setConfirmationModal] = useState();
  const [userData, setUserData] = useState(user);

  const {
    isLoading: isLoadingUpdateUser,
    update: updateUser,
    data: updateUserData,
  } = useUpdateUser();

  const {
    isLoading: isLoadingCreateUser,
    mutate: createUser,
    data: createUserData,
  } = useCreateUser();

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    get: getUserRoles,
  } = useGetAllUserRoles();

  useEffect(() => {
    if (updateUserData) {
      sharedHelper.successToast("User saved");
      onSubmit();
    }
  }, [updateUserData, onSubmit]);

  useEffect(() => {
    if (createUserData) {
      sharedHelper.successToast(`User created`);
      onSubmit();
    }
  }, [createUserData, onSubmit]);

  useEffect(() => {
    getEmployees({
      pageSize: MAX_PAGE_SIZE,
    });
  }, [getEmployees]);

  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (userData.id) {
      await updateUser(userData);
    } else {
      await createUser(userData);
    }
  };

  const onClearTokens = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal();
        await updateUser({ ...userData, clearTokens: true });
        sharedHelper.successToast("User session terminated");
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `Terminate user session`,
      body: `<span>The user session will be terminated and will need to relogin.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
    });
  };

  const onSendResetDB = (action) => {
    const isReset = action === "appResetDB";
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal();
        await updateUser({ ...userData, [action]: true });
        sharedHelper.successToast(`User DB was ${isReset ? "reset" : "sent"}`);
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `${isReset ? "Reset" : "Send"} User DB`,
      body: `<span>The user DB will be sent by email${
        isReset ? " and then reset" : ""
      }.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
    });
  };

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const appUserRoles =
    userRoles
      ?.filter((role) => sharedHelper.isRoleAppUser(role))
      .map((role) => role.id) || [];

  return confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {userData.id ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingCreateUser || isLoadingUpdateUser ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label>
                    <span>First Name</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    disabled={user.employeeId ? true : false}
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.firstName || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        firstName: e.target.value,
                      })
                    }
                    required
                    placeholder="Enter first name..."
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Last Name</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    disabled={user.employeeId ? true : false}
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.lastName || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        lastName: e.target.value,
                      })
                    }
                    required
                    placeholder="Enter last name..."
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Email</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    maxLength="50"
                    type="email"
                    name="email"
                    value={userData.email || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                    placeholder="Enter email..."
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Password</span>
                    {!userData.id ? (
                      <span className="text-danger ms-2">*</span>
                    ) : null}
                  </Label>
                  <input
                    className="form-control-redesign"
                    required={!userData.id}
                    autoComplete="new-password"
                    maxLength="50"
                    type="password"
                    name="password"
                    value={userData.password || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        password: e.target.value.trim(),
                      })
                    }
                    placeholder="Enter password..."
                  />
                </FormGroup>
                {appUserRoles.includes(userData.userRoleId) ? (
                  <FormGroup>
                    <Label>
                      <span>NFC Token</span>
                    </Label>
                    <input
                      className="form-control-redesign"
                      maxLength="50"
                      type="text"
                      name="NFCToken"
                      value={userData.NFCToken || ""}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          NFCToken: e.target.value,
                        })
                      }
                      placeholder="Enter NFC token..."
                    />
                  </FormGroup>
                ) : null}
                {isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : (
                  <FormGroup>
                    <Label>
                      <span>Role</span>
                      <span className="text-danger ms-2">*</span>
                    </Label>
                    <select
                      className="form-control-redesign"
                      type="select"
                      name="roleSelect"
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          userRoleId: e.target.value,
                        })
                      }
                      value={userData.userRoleId}
                      data-testid="role-select"
                      required
                    >
                      <option value={""}>Select a Role</option>
                      {userRoles?.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                )}
                {isLoadingEmployees || isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : appUserRoles.includes(userData.userRoleId) ? (
                  <FormGroup>
                    <Label>
                      <span>Employee</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <select
                      className="form-control-redesign"
                      //required
                      type="select"
                      name="employeeSelect"
                      data-testid="employee-select"
                      onChange={(e) => {
                        const employee = employees.data.find(
                          (s) => s.id === e.target.value
                        );
                        setUserData({
                          ...userData,
                          employeeId: employee?.id,
                        });
                      }}
                      value={userData.employeeId || ""}
                    >
                      <option value={""}>Select an employee</option>
                      {employees?.data.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {`${employee.firstName} ${employee.lastName}`}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                ) : null}
                {userData.id && isSuperAdmin ? (
                  <div className="d-flex my-3 align-items-center justify-content-center">
                    <div className="col-4 px-1 d-none">
                      <Button
                        size="sm"
                        color="primary"
                        className="w-100"
                        onClick={() => onSendResetDB("appSendDB")}
                      >
                        Send APP DB
                      </Button>
                    </div>
                    <div className="col-4 px-1 d-none">
                      <Button
                        size="sm"
                        color="primary"
                        className="w-100"
                        onClick={() => onSendResetDB("appResetDB")}
                      >
                        Reset APP DB
                      </Button>
                    </div>
                    <div className="col-4 px-1">
                      <Button
                        className="w-100"
                        size="sm"
                        color="primary"
                        onClick={onClearTokens}
                      >
                        Kill Session
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
