import React, { useState, useEffect } from "react";

import { components, sharedHelper } from "@crewos/shared";

import {
  Input,
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { useUpdateLocationCustomerContacts } from "../api/Customers.hooks";

const { Loader } = components;

const CustomerLocationContactsModal = ({
  customerLocation,
  onClose,
  onSubmit,
}) => {
  const [newContact, setNewContact] = useState({ open: false });
  const [customerContacts, setCustomerContacts] = useState(
    customerLocation.contacts
  );

  const {
    isLoading: isLoadingUpdateCustomerContacts,
    update: updateLocationCustomerContacts,
    data: updateLocationCustomerContactsData,
  } = useUpdateLocationCustomerContacts();

  const doSubmit = async () => {
    if (!customerContacts.length) {
      setCustomerContacts(customerLocation.contacts);
      return sharedHelper.warningToast("You must enter at least one contact");
    }
    updateLocationCustomerContacts({
      id: customerLocation.id,
      customerContacts,
    });
  };

  useEffect(() => {
    if (updateLocationCustomerContactsData) {
      onSubmit();
    }
  }, [updateLocationCustomerContactsData, onSubmit]);

  const onDiscard = () => setNewContact({ open: false });

  const onDelete = (index) => {
    let newCustomerContacts = [...customerContacts];
    newCustomerContacts.splice(index, 1);
    setCustomerContacts(newCustomerContacts);
  };

  const onSave = (event) => {
    event.preventDefault();
    const { name, email, phone } = newContact;
    setCustomerContacts([
      ...customerContacts,
      {
        name,
        email,
        phone,
      },
    ]);
    setNewContact({ open: false });
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <Form onSubmit={onSave}>
        <ModalHeader
          toggle={onClose}
          className="d-flex justify-content-between"
        >
          Edit Contacts for Location
        </ModalHeader>
        <ModalBody className="text-center">
          <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
            <thead>
              <tr className="bg-lighter small text-muted">
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                {customerContacts.length || newContact.open ? (
                  <th className="min-width-50"></th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {customerContacts.map((customerContact, index) => (
                <tr key={index}>
                  <td>{customerContact.name}</td>
                  <td>{customerContact.email}</td>
                  <td>{customerContact.phone}</td>
                  <td>
                    <div
                      onClick={() => onDelete(index)}
                      className="cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrash} className="text-danger" />
                    </div>
                  </td>
                </tr>
              ))}
              {!customerContacts.length && !newContact.open ? (
                <tr>
                  <td colSpan="4">No customer contacts</td>
                </tr>
              ) : null}
              {newContact.open ? (
                <tr>
                  <td>
                    <Input
                      className="border-0 text-center"
                      required={true}
                      placeholder="Name.."
                      onChange={(event) =>
                        setNewContact({
                          ...newContact,
                          name: event.currentTarget.value,
                        })
                      }
                    />
                  </td>
                  <td>
                    <Input
                      className="border-0 text-center"
                      required={true}
                      placeholder="Email.."
                      type="email"
                      onChange={(event) =>
                        setNewContact({
                          ...newContact,
                          email: event.currentTarget.value.trim(),
                        })
                      }
                    />
                  </td>
                  <td>
                    <Input
                      className="border-0 text-center"
                      required={true}
                      placeholder="Phone.."
                      onChange={(event) =>
                        setNewContact({
                          ...newContact,
                          phone: event.currentTarget.value,
                        })
                      }
                    />
                  </td>
                  <td></td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <div>
            {newContact.open ? (
              <Button color="secondary" onClick={onDiscard}>
                Discard
              </Button>
            ) : (
              <>
                <Button color="secondary" onClick={onClose}>
                  Discard
                </Button>
                <Button
                  className="ms-2"
                  color="primary"
                  onClick={() => setNewContact({ open: true })}
                >
                  Create New
                </Button>
              </>
            )}
          </div>
          {isLoadingUpdateCustomerContacts ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : newContact.open ? (
            <Button className="ms-2" color="primary" type="submit">
              Save
            </Button>
          ) : (
            <Button
              className="d-flex align-items-center"
              color="primary"
              onClick={doSubmit}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomerLocationContactsModal;
