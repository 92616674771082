import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Loader from "./Loader";

const ConfirmationModal = ({
  onSubmit = () => { },
  onClose = () => { },
  title = "",
  body = "",
  confirmColor = "primary",
  size = "sm",
}) => {
  const [loading, setLoading] = useState(false);

  const doSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size={size}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title}
      </ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={onClose} className="text-dark">
          Cancel
        </Button>{" "}
        <Button disabled={loading} color={confirmColor} onClick={doSubmit}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
