import React from "react";

import { data, sharedHelper } from "@crewos/shared";

import Equipments from "./pages/Equipments";
import CrewWorkDay from "./components/CrewWorkDay";
import CrewsEquipments from "./components/CrewsEquipments";

import { faCogs } from "@fortawesome/free-solid-svg-icons";

import { Route, Outlet } from "react-router-dom";

const { useAuth, SIDEBAR_ACTIONS } = data;

const EQUIPMENTS = "EQUIPMENTS";

// Routes
const routes = [
  {
    path: "/equipments",
    name: "Equipments",
    package: "equipments",
    icon: faCogs,
    sidebar: true,
    scopes: [],
    component: Equipments,
  },
];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "equipments"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="/equipments" element={<Wrapper />}>
      {routes.flatMap((route, index) =>
        route.entries
          ? route.entries.map((entry, index) => (
              <Route
                key={index}
                path={entry.path}
                element={<entry.component />}
              />
            ))
          : [
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />,
            ]
      )}
    </Route>
  </React.Fragment>
);

const setEquipmentSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "equipments");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: EQUIPMENTS, order: 6 },
    });
  }
};

export {
  CrewWorkDay,
  CrewsEquipments,
  Routes as EquipmentRoutes,
  setEquipmentSidebarRoutes,
};
