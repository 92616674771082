import React, { useState, useCallback, useRef, useMemo } from "react";
import ReactQuill from "react-quill";
import { Row, Col, Button, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCircleInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";

const GuidanceForm = ({ guidance, questionId }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [text, setText] = useState(guidance.text);
  const [attachment, setAttachment] = useState(guidance.attachment);

  const { updateGuidance } = useInspectionTemplate();

  const fileInputRef = useRef(null);

  const handleCollapse = useCallback(($event) => {
    $event.stopPropagation();
    setIsExpanded((status) => !status);
  }, []);

  const handleAttachmentChange = useCallback(($event) => {
    const file = $event.target.files[0];
    const updatedFile = new File([file], `guidance_${Date.now()}`, {
      type: file.type,
    });

    setAttachment(updatedFile);
    updateGuidance(questionId, { text, attachment: updatedFile });
  }, [questionId, text, updateGuidance]);

  const handleRemoveAttachment = useCallback(() => {
    setAttachment(null);
    updateGuidance(questionId, { text, attachment: null });
  }, [questionId, text, updateGuidance]);

  const handleUploadAttachment = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleTextChange = useCallback((value) => {
    setText(value);
    updateGuidance(questionId, { text: value, attachment });
  }, [questionId, updateGuidance, attachment]);

  const attachmentValue = useMemo(() => {
    if (!attachment) return null;
    return attachment instanceof File ? URL.createObjectURL(attachment) : attachment;
  }, [attachment]);

  return (
    <div className="border border-radius-md p-3 mx-0 my-3" sm={2}>
      <Row onClick={handleCollapse}>
        <Col sm={6}>
          <span className="fw-bold text-lg">Guidance</span>
        </Col>
        <Col className="d-flex justify-content-end" sm={6}>
          <Button
            className="btn btn-link d-flex align-items-center gap-2 expand-button text-decoration-none"
          >
            <small className="text-primary fw-bold">
              View {isExpanded ? "less" : "more"}
            </small>
            <FontAwesomeIcon
              className={`ms-2 rotate ${isExpanded ? "rotate-90" : ""}`}
              icon={faChevronRight}
            />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isExpanded}>
        <Row>
          <Col>
            <div className="mx-0 my-3">
              <FontAwesomeIcon icon={faCircleInfo} />
              <span className="ms-2">Guidance text:</span>
            </div>
            <ReactQuill
              theme="snow"
              value={text}
              onChange={handleTextChange}
              className="border rounded"
            />
          </Col>
          <Col>
            <div className="mx-0 my-3">
              <FontAwesomeIcon icon={faCircleInfo} />
              <span className="ms-2">Guidance attachment (jpg, png):</span>
            </div>
            <div>
              <input
                type="file"
                value=""
                onChange={handleAttachmentChange}
                className="d-none"
                id="guidance-attachment"
                accept="image/*"
                name="guidance-attachment"
                data-testid="guidance-attachment"
                ref={fileInputRef}
              />
              {attachment ? (
                <div className="mt-2 d-flex align-items-start gap-2">
                  <img
                    src={attachmentValue}
                    alt="Guidance attachment preview"
                    className="img-fluid rounded"
                    style={{ maxWidth: '64px', maxHeight: '64px' }}
                  />
                  <Button
                    color="link"
                    className="rounded-circle d-flex custom-rounded-button text-danger border-0"
                    onClick={handleRemoveAttachment}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleUploadAttachment}
                >
                  Upload Image
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default GuidanceForm;
