import React from "react";

import { Card, CardBody, CardHeader, Container } from "reactstrap";

const ReportingTool = () => {
  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card className="mb-3 w-100 box-shadow-none h-100">
        <CardHeader className="pt-2 pb-3 d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Reporting Tool</h2>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-auto p-4 h-100">
          <iframe
            src="http://localhost:8088/superset/dashboard/p/nEoeaxYK58Z/"
            style={{ width: "100%", height: "100%", border: "none" }}
            allowTransparency
            allowFullScreen
            title="Metabase Dashboard"
          ></iframe>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ReportingTool;
