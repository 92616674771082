import React, { useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
} from "reactstrap";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components } from "@crewos/shared";

const { TooltipItem } = components;

const NewSmartReportModal = ({ onClose, onSubmit, context }) => {
  const [data, setData] = useState("");
  const [from, setFrom] = useState("prompt");

  const doSubmit = (evt) => {
    evt.preventDefault();
    onSubmit(data, from);
  };

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          <div className="d-flex align-items-center">
            <span>New Smart Report</span>
            <TooltipItem id="context-tooltip" title={`${context} Context`}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-primary text-warning ms-2"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <select
              className="form-control-redesign"
              type="select"
              name="typeSelect"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
              required
            >
              <option value="prompt">From Prompt</option>
              <option value="query">From Query</option>
            </select>
          </FormGroup>
          <FormGroup>
            <textarea
              className={from === "prompt" ? "form-control" : "code"}
              type="text"
              wrap="soft"
              maxLength="255"
              style={{ height: 100 }}
              placeholder={
                from === "prompt"
                  ? "Describe the data you need"
                  : "Enter a MySQL query"
              }
              value={data}
              onChange={(e) => setData(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Generate
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NewSmartReportModal;
