import React, { useEffect } from "react";

import { useParams } from "react-router";

import { Route, Outlet } from "react-router-dom";

import {
  useCrewWorkDays,
  ACTIONS as CREW_WORK_DAY_ACTIONS,
  CrewWorkDaysProvider,
} from "./providers/crewWorkDaysProvider";

import WorkOrderCrews from "./components/WorkOrderCrews";
import WorkPerformed from "./components/WorkPerformed";
import CrewWorkDayCrew from "./components/CrewWorkDayCrew";
import WorkOrderCrewsTimes from "./components/WorkOrderCrewsTimes";
import WorkOrderCrewsEquipments from "./components/WorkOrderCrewsEquipments";
import WorkOrderCrewsExpenses from "./components/WorkOrderCrewsExpenses";
import WorkOrderCrewsPerDiems from "./components/WorkOrderCrewsPerDiems";
import WorkOrderCrewsInspections from "./components/WorkOrderCrewsInspections";
import CrewWorkDayTimes from "./components/CrewWorkDayTimes";
import { crewHelper } from "./helpers/crewHelper";
import { useGetCrewWorkDays } from "./api/CrewWorkDays.hooks";
import { useGetCrews } from "./api/Crews.hooks";

import { data, sharedHelper, components } from "@crewos/shared";
import { SettingRoutes, settingsSidebarRoutes } from "./routes/SettingsRoutes";
import {
  useCrews,
  ACTIONS as CREWS_ACTIONS,
  CrewsProvider,
} from "./providers/crewsProvider";

const { Loader } = components;

const { useAuth, SIDEBAR_ACTIONS } = data;

const routes = [];

const CREWS = "CREWS";

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "crews"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="/crews" element={<Wrapper />}>
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

const CrewsData = ({ children }) => {
  const { workOrderId } = useParams();

  const [crewsContext, setCrewsContext] = useCrews();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const {
    isLoading: isLoadingGetCrewWorkDays,
    get: getCrewWorkDays,
    data: crewWorkDaysData,
  } = useGetCrewWorkDays();

  useEffect(() => {
    getCrewWorkDays({ workOrderId });
  }, [getCrewWorkDays, crewWorkDaysContext.refresh, workOrderId]);

  useEffect(() => {
    if (crewWorkDaysData) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.GET_CREW_WORK_DAYS_SUCCESS,
        payload: {
          crewWorkDays: crewWorkDaysData,
        },
      });
    }
  }, [crewWorkDaysData, setCrewWorkDaysContext]);

  const {
    isLoading: isLoadingGetCrews,
    get: getCrews,
    data: crews,
  } = useGetCrews();

  useEffect(() => {
    getCrews({ workOrderId });
  }, [getCrews, crewsContext.refresh, workOrderId]);

  useEffect(() => {
    if (crews) {
      setCrewsContext({
        action: CREWS_ACTIONS.GET_CREWS_SUCCESS,
        payload: { crews },
      });
    }
  }, [crews, setCrewsContext]);

  return isLoadingGetCrewWorkDays || isLoadingGetCrews ? <Loader /> : children;
};

const CrewsDataWrapper = (props) => (
  <CrewsProvider>
    <CrewWorkDaysProvider>
      <CrewsData {...props} />
    </CrewWorkDaysProvider>
  </CrewsProvider>
);

const setCrewSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "crews");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: CREWS, order: 6 },
    });
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

export {
  useGetCrews,
  setCrewSidebarRoutes,
  Routes as CrewRoutes,
  crewHelper,
  useCrewWorkDays,
  CrewWorkDaysProvider,
  CREW_WORK_DAY_ACTIONS,
  CrewsDataWrapper as CrewsData,
  CrewWorkDayCrew,
  WorkPerformed,
  CrewWorkDayTimes,
  WorkOrderCrews,
  WorkOrderCrewsTimes,
  WorkOrderCrewsEquipments,
  WorkOrderCrewsExpenses,
  WorkOrderCrewsPerDiems,
  WorkOrderCrewsInspections,
  useCrews,
};
