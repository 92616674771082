import React from "react";

import AddWorkTimeModal from "./components/AddWorkTimeModal";
import EditWorkTimeModal from "./components/EditWorkTimeModal";
import CrewWorkDayWorkTimes from "./components/CrewWorkDayWorkTimes";
import { useGetAllWorkTimeTypes } from "./api/WorkTimeTypes.hooks";

import { Route, Outlet } from "react-router-dom";

import { data, sharedHelper } from "@crewos/shared";
import { SettingRoutes, settingsSidebarRoutes } from "./routes/SettingsRoutes";

const { useAuth, SIDEBAR_ACTIONS } = data;

const WORKTIMES = "WORKTIMES";

const routes = [];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "worktimes"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="/worktimes" element={<Wrapper />}>
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

const setWorkTimeSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "worktimes");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: WORKTIMES, order: 6 },
    });
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

export {
  Routes as WorkTimeRoutes,
  setWorkTimeSidebarRoutes,
  EditWorkTimeModal,
  CrewWorkDayWorkTimes,
  AddWorkTimeModal,
  useGetAllWorkTimeTypes,
};
