import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateRangeLabel = ({ title, startDate, endDate, background }) => {
  return (
    <div className="small d-flex align-items-center justify-content-center">
      {title ? <span className="me-2 fw-bold">{title}: </span> : null}
      <div
        className="d-flex align-items-center justify-content-center px-2 py-1 border rounded"
        style={{ background }}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className="me-2 text-primary-light"
          style={{ paddingBottom: "1px" }}
        />
        <span>
          {startDate ? moment(startDate).format("MM/DD/YYYY") : "Not Set"}
        </span>
      </div>
      <span className="mx-2">to</span>
      <div
        className="d-flex align-items-center justify-content-center px-2 py-1 border rounded"
        style={{ background }}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className="me-2 text-primary-light"
          style={{ paddingBottom: "1px" }}
        />
        <span>
          {endDate ? moment(endDate).format("MM/DD/YYYY") : "Not Set"}
        </span>
      </div>
    </div>
  );
};

export default DateRangeLabel;
