import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import brand from "../assets/img/logoPrimary.png";
import powered from "../assets/img/powered.svg";
import poweredCrewOS from "../assets/img/logo_powered.svg";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { data, components, api, sharedHelper } from "@crewos/shared";

const { useAuth, AUTH_ACTIONS } = data;
const { authApi } = api;
const { Loader } = components;

export const LoginForm = () => {
  const [, setAuthContext] = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDismiss = () => setError(false);

  const doLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    authApi
      .login({
        email,
        password,
      })
      .then((userData) => {
        setLoading(false);
        if (!userData) {
          return null;
        }
        setAuthContext({
          action: AUTH_ACTIONS.SET_USER_DATA,
          payload: { userData },
        });
      })
      .catch((err) => {
        setLoading(false);
        sharedHelper.errorToast(err);
      });
    return false;
  };

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <Card className="login-box mx-auto bg-white">
        <CardBody className="form-container mx-auto d-flex flex-column">
          <div className="text-center">
            <img
              src={brand}
              className="p-3 col-8 my-4 bg-primary"
              alt={"CrewOS"}
            />
            <h1 className="mt-3 mb-5 text-primary">Welcome Back!</h1>
          </div>
          <Form onSubmit={doLogin} className="flex-grow-1 d-flex flex-column">
            <FormGroup className="d-flex flex-column">
              <Label className="fw-bold" for="emailUser">
                Email
              </Label>
              <input
                className="mb-2 login-input"
                id="emailUser"
                data-test-id="email-input"
                maxLength="50"
                required={true}
                name="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value.trim())}
              />
            </FormGroup>
            <FormGroup className="d-flex flex-column">
              <Label className="fw-bold" for="passwordUser">
                Password
              </Label>
              <input
                className="login-input"
                id="passwordUser"
                required={true}
                maxLength="50"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
            </FormGroup>
            <div className="text-center mt-3 flex-grow-1 d-flex flex-column justify-content-center">
              <UncontrolledAlert
                isOpen={!!error}
                toggle={onDismiss}
                color="warning"
              >
                <div className="alert-icon">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="alert-message text-start">
                  <span className="ms-2">{error}</span>
                </div>
              </UncontrolledAlert>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <Button
                  className="w-100"
                  id="signIn"
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  LOGIN
                </Button>
              )}
              <small className="mt-4">
                <Link to="/auth/reset">Forgot your password?</Link>
              </small>
            </div>
          </Form>
        </CardBody>
      </Card>
      <div className="d-flex flex-column mx-auto pb-4 align-items-end">
        <img alt={"CrewOS"} className="powered" src={powered} />
        <img alt={"CrewOS"} src={poweredCrewOS} />
      </div>
    </div>
  );
};
