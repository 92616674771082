import React from "react";

import { data, sharedHelper } from "@crewos/shared";

import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { Route, Outlet } from "react-router-dom";

import Users from "./pages/Users";
import { SettingRoutes, settingsSidebarRoutes } from "./routes/SettingsRoutes";

const { useAuth, SIDEBAR_ACTIONS } = data;

const USERS = "USERS";

// Routes
const routes = [
  {
    path: "/users",
    name: "Users",
    package: "users",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    icon: faUsers,
    sidebar: true,
    component: Users,
  },
];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "users"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="/users" element={<Wrapper />}>
      {routes.flatMap((route, index) =>
        route.entries
          ? route.entries.map((entry, index) => (
              <Route
                key={index}
                path={entry.path}
                element={<entry.component />}
              />
            ))
          : [
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />,
            ]
      )}
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

const setUserSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "users");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: USERS, order: 6 },
    });
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

export { Routes as UserRoutes, setUserSidebarRoutes };
