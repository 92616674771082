import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import {
  useDeleteTemplate,
  useDuplicateTemplate,
} from "../api/Inspections.hooks";
import RestrictEditTemplateModal from "./RestrictEditTemplateModal";

const TemplateItem = ({ template, onDelete, onCopy }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRestrictEditModalOpen, setIsRestrictEditModalOpen] = useState(false);
  const { isLoading, mutate: deleteTemplate } = useDeleteTemplate();
  const { isLoading: isCopying, mutate: copyTemplate } = useDuplicateTemplate(
    template.id
  );

  const handleCopy = useCallback(async () => {
    await copyTemplate({});
    onCopy();
  }, [copyTemplate, onCopy]);

  const handleRemove = useCallback(async () => {
    await deleteTemplate({ id: template.id });
    setIsModalOpen((prev) => !prev);
    onDelete();
  }, [template, deleteTemplate, onDelete]);

  const handleEdit = useCallback(() => {
    if (template.totalInspections > 0) {
      setIsRestrictEditModalOpen((prev) => !prev);
    } else {
      navigate(`edit/${template.id}`, { relative: "path" });
    }
  }, [navigate, template, setIsRestrictEditModalOpen]);

  return (
    <Col className="d-flex flex-column">
      <Card key={template.id} className="p-2 flex-grow-1">
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4 className="text-truncate mb-0">{template.name}</h4>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button border-0"
              color="white"
              onClick={handleCopy}
              disabled={isLoading || isCopying}
              aria-label={`Copy ${template.name}`}
            >
              <FontAwesomeIcon icon={faCopy} size="xl" />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button text-danger border-0"
              color="white"
              onClick={() => setIsModalOpen((prev) => !prev)}
              disabled={isLoading || isCopying}
              aria-label={`Delete ${template.name}`}
            >
              <FontAwesomeIcon icon={faTrash} size="xl" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="text-black-50">
          <span>{template.totalSections} sections, </span>
          <span>{template.totalQuestions} questions</span>
        </CardBody>
        <CardFooter>
          <Button
            color="secondary"
            className="w-100 rounded-4 py-2"
            onClick={handleEdit}
            disabled={isLoading}
          >
            {template.status === "PUBLISHED" ? "Edit" : "Continue"}
          </Button>
        </CardFooter>
      </Card>
      <DeleteModal
        title="Delete Template"
        name={template.name}
        isOpen={isModalOpen}
        onDelete={handleRemove}
        isLoading={isLoading}
        onClose={() => setIsModalOpen((prev) => !prev)}
      />
      <RestrictEditTemplateModal
        isOpen={isRestrictEditModalOpen}
        onClose={() => setIsRestrictEditModalOpen((prev) => !prev)}
        template={template}
        onDuplicate={handleCopy}
      />
    </Col>
  );
};

export default TemplateItem;
