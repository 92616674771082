import React, { useCallback, memo } from "react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import QuestionItem from "./QuestionItem";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import SortableWrapper from "./SortableWrapper";

const Questions = ({ questions, groupId }) => {
  const { updateQuestionsOrder, addQuestion } = useInspectionTemplate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active.id !== over.id) {
        const oldIndex = questions.findIndex((item) => item.id === active.id);
        const newIndex = questions.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(questions, oldIndex, newIndex);
        updateQuestionsOrder(newItems);
      }
    },
    [updateQuestionsOrder, questions]
  );

  const handleAddQuestion = useCallback(() => {
    addQuestion(groupId);
  }, [addQuestion, groupId]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={questions?.map((question) => question.id)}
        strategy={verticalListSortingStrategy}
      >
        {questions?.map((question) => (
          <SortableWrapper
            id={question.id}
            key={question.id}
            className="question-item sortable-wrapper"
          >
            <QuestionItem question={question} />
          </SortableWrapper>
        ))}
        <Row>
          <Col className="d-grid gap-2 add-btn-container">
            <Button
              size="sm"
              color="white"
              className="btn-dashed"
              onClick={handleAddQuestion}
            >
              <FontAwesomeIcon icon={faCirclePlus} size="xl" /> Add Question
            </Button>
          </Col>
        </Row>
      </SortableContext>
    </DndContext>
  );
};

export default memo(Questions);
