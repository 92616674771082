import React, { useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { sharedHelper, components, data } from "@crewos/shared";

import { useGetWorkTimes } from "../api/WorkTimes.hooks";
import EmployeeCrewRow from "./EmployeeCrewRow";
import { crewHelper } from "@crewos/crews";

const { useAuth, useWorkOrderDetails } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";
const CREWS_PACKAGE = "crews";

const { Loader } = components;

const CrewWorkDayWorkTimes = ({ crewWorkDay, crew }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [workTimes, setWorkTimes] = useState([]);
  const [refresh, setRefresh] = useState();

  const isReadOnly = workOrderDetails.activeTab === JOB_DETAILS_TAB;

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const {
    data: getWorkTimesData,
    isLoading: isLoadingGetWorkTimes,
    get: getWorkTimes,
  } = useGetWorkTimes();

  useEffect(() => {
    getWorkTimes({
      crewWorkDayId: crewWorkDay.id,
    });
  }, [getWorkTimes, refresh, crewWorkDay]);

  useEffect(() => {
    if (getWorkTimesData) {
      setWorkTimes(getWorkTimesData);
    }
  }, [getWorkTimesData, setWorkTimes]);

  const activeCrew = crew.employeeCrews.filter((ec) =>
    crewHelper.isEmployeeCrewActiveOnDate(
      employeeCrewPastDatesEnabled,
      ec,
      crewWorkDay.date
    )
  );

  return (
    <Card className="w-100 mb-3 px-0">
      <CardBody className="pb-2">
        <div className="mb-n1 d-flex align-items-center justify-content-between">
          <h3>Work Time</h3>
          <div className="d-flex">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </div>
        {!directAssignationEnabled ? (
          <div
            key={crewWorkDay.id}
            className="mt-3 fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-lighter border-radius-default"
          >
            <div className="col-4">CREW MEMBER</div>
            <div className={`col-6 text-${isReadOnly ? "end" : "center"}`}>
              WORK TIME
            </div>
            {!isReadOnly ? <div className="col-2"></div> : null}
          </div>
        ) : null}
        {isLoadingGetWorkTimes ? (
          <Loader size="sm" className="my-3" />
        ) : activeCrew.length ? (
          !workTimes.length ? (
            <div className="my-1 p-2 small text-muted text-center">
              No work times
            </div>
          ) : (
            activeCrew
              .sort(sharedHelper.sortEmployeeCrew)
              .map((employeeCrew) => {
                const employeeWorkTimes = workTimes.filter(
                  (wt) => wt.employeeId === employeeCrew.employeeId
                );
                return (
                  <EmployeeCrewRow
                    key={employeeCrew.id}
                    employeeCrew={employeeCrew}
                    workTimes={employeeWorkTimes}
                    onRefresh={() => setRefresh((prev) => !prev)}
                  />
                );
              })
          )
        ) : (
          <div className="my-1 p-2 small text-muted text-center">
            Empty crew
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const CrewWorkDayWorkTimesWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(authContext.userData?.packages, "worktimes")
  ) {
    return null;
  }

  return <CrewWorkDayWorkTimes {...props} />;
};

export default CrewWorkDayWorkTimesWrapper;
