import { useCrews } from "@crewos/crews";
import CrewWorkDayTemplatesWO from "./CrewWorkDayTemplatesWO";
import { useGetTemplateWorkOrder } from "../api/Inspections.hooks";
import React, {useCallback, useEffect, useMemo} from "react";
import CrewWorkDayInspectionsWrapper from "./CrewWorkDayInspections";
import { components } from "@crewos/shared";

const { Loader } = components;

const CrewWorkDay = ({ crewWorkDay, isViewPerDay, workOrder, needsUpdate, search }) => {
  const [crewsContext] = useCrews();
  const {
    data: templateWOData,
    isLoading: isLoadingTemplateWO,
    get: getTemplateWO,
  } = useGetTemplateWorkOrder();

  const handleGetTemplateWO = useCallback(() => {
    const payload = {
      workOrderId: workOrder.id,
      crewWorkDayId: isViewPerDay ? crewWorkDay.id : undefined,
    };
    getTemplateWO(payload);
  }, [workOrder.id, crewWorkDay.id, isViewPerDay, getTemplateWO]);

  useEffect(() => {
    handleGetTemplateWO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewPerDay, needsUpdate]);

  const templateWOFiltered = useMemo(() => {
    if (!templateWOData || !Array.isArray(templateWOData)) {
      return [];
    }
    if (search) {
      return templateWOData.filter((tempWO) => tempWO.name.toLowerCase().includes(search) || tempWO.template.name.toLowerCase().includes(search));
    }
    return templateWOData;
  }, [templateWOData, search]);

  const crew = crewsContext.crews.find(
    (crew) => crew.id === crewWorkDay.crewId
  );
  if (!crew) {
    return null;
  }
  if (isLoadingTemplateWO) {
    return <Loader size="sm" />;
  }

  return (
    templateWOFiltered.map((templateWO) => (
      <CrewWorkDayTemplatesWO
        crew={crew}
        key={templateWO.id}
        templateWorkOrder={templateWO}
      >
        <CrewWorkDayInspectionsWrapper
          inspections={templateWO.inspections}
          onInspectionDelete={handleGetTemplateWO}
        />
      </CrewWorkDayTemplatesWO>
    ))
  );
};

export default CrewWorkDay;
