import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const RestrictEditTemplateModal = ({ isOpen, onClose, template, onDuplicate }) => {

  const handleDuplicate = useCallback(() => {
    onDuplicate();
    onClose();
  }, [onDuplicate, onClose]);

  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader toggle={onClose}>
        Restrict Edit Template
      </ModalHeader>
      <ModalBody>
        <p>
          This template has been used in {template.totalInspections} inspections.
        </p>
        <p>
          Editing it will affect the inspections.
        </p>
        <p>
          If you still want to edit it, you can duplicate it.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleDuplicate}>Duplicate</Button>
      </ModalFooter>
    </Modal>
  );
};

export default RestrictEditTemplateModal;
