import React from "react";

import { useGetCustomers } from "./api/Customers.hooks";
import { data, sharedHelper } from "@crewos/shared";

import Customers from "./pages/Customers";
import CustomerDetails from "./pages/CustomerDetails";

import { faHandshake } from "@fortawesome/free-solid-svg-icons";

import { Route, Outlet } from "react-router-dom";

const { useAuth, SIDEBAR_ACTIONS } = data;

const CUSTOMER = "CUSTOMER";

// Routes
const routes = [
  {
    path: "/customers",
    name: "Customers",
    package: "customers",
    icon: faHandshake,
    sidebar: true,
    scopes: [],
    component: Customers,
  },
  {
    path: `/customers/details/:customerId`,
    name: "Customers Details",
    component: CustomerDetails,
  },
];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "customers"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const setCustomerSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "customers");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: CUSTOMER, order: 6 },
    });
  }
};

const Routes = (
  <React.Fragment>
    <Route path="/customers" element={<Wrapper />}>
      {routes.flatMap((route, index) =>
        route.entries
          ? route.entries.map((entry, index) => (
              <Route
                key={index}
                path={entry.path}
                element={<entry.component />}
              />
            ))
          : [
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />,
            ]
      )}
    </Route>
  </React.Fragment>
);

export { useGetCustomers, Routes as CustomerRoutes, setCustomerSidebarRoutes };
