import React from "react";

import { Button, Input, Label } from "reactstrap";

import { crewHelper } from "@crewos/crews";

import moment from "moment";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { sharedHelper } from "@crewos/shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const TRAVEL_TIMES_PACKAGE = "traveltimes";

const CREWS_PACKAGE = "crews";

const CrewMembers = ({
  selectedDate,
  crew,
  timeToAdd,
  isDriver,
  mileageToAdd,
  employeeEnabled,
  setTimeToAdd,
  setIsDriver,
  setMileageToAdd,
  setEmployeeEnabled,
}) => {
  const [authContext] = useAuth();

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    TRAVEL_TIMES_PACKAGE,
    START_END_TRAVEL_TIME_SETTING
  );

  const onMileageChange = (evt, employeeId) => {
    setMileageToAdd({
      ...mileageToAdd,
      [employeeId]: evt.target.value,
    });
  };

  const onHoursChange = (evt, employeeId) => {
    setTimeToAdd({
      ...timeToAdd,
      [employeeId]: evt.target.value,
    });
  };

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const lead = crewHelper.getCrewLead(employeeCrewPastDatesEnabled, crew);

  return (
    <div className="mt-3">
      <div className="fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-lighter border-radius-default">
        {`${lead.employee.firstName} ${lead.employee.lastName}`}
      </div>
      {crew.employeeCrews
        .filter((employeeCrew) =>
          crewHelper.isEmployeeCrewActiveOnDate(
            employeeCrewPastDatesEnabled,
            employeeCrew,
            selectedDate
          )
        )
        .sort(sharedHelper.sortEmployeeCrew)
        .map((employeeCrew) => {
          const employee = employeeCrew.employee;
          const travelTime = timeToAdd[employee.id] || {};
          let hours = timeToAdd[employee.id];

          const startTime = travelTime.startTime
            ? moment(travelTime.startTime).format("HH:mm")
            : null;

          const endTime = travelTime.endTime
            ? moment(travelTime.endTime).format("HH:mm")
            : null;

          if (startEndTimeSettingEnabled) {
            hours = (
              moment(travelTime.endTime).diff(
                moment(travelTime.startTime),
                "minutes"
              ) / 60
            ).toFixed(2);
          }

          return (
            <div
              key={employeeCrew.id}
              className="mt-1 d-flex justify-content-between align-items-start p-2 border-bottom col-12"
              tag="div"
            >
              <div className="d-flex flex-column align-items-start flex-grow-1">
                <small>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</small>
                <small className="text-muted text-left">{`${employeeCrew.role.name}`}</small>
              </div>
              <div className="ms-5 flex-grow-1 d-flex justify-content-end align-items-center align-self-center">
                {employeeEnabled[employeeCrew.employee.id] ? (
                  <div className="col-12 px-0 d-flex flex-column align-items-start">
                    <div
                      className="d-flex justify-content-between align-items-center mb-2 cursor-pointer position-relative w-100"
                      tag="div"
                    >
                      <div className="d-flex align-items-center">
                        <Label
                          for={`isDriver-${employeeCrew.employee.id}`}
                          check
                          className="cursor-pointer small mb-0"
                        >
                          <Input
                            checked={isDriver[employeeCrew.employee.id]}
                            onChange={(event) =>
                              setIsDriver({
                                ...isDriver,
                                [employeeCrew.employee.id]:
                                  event.target.checked,
                              })
                            }
                            type="switch"
                            data-testid="isDriver-switch"
                            id={`isDriver-${employeeCrew.employee.id}`}
                            name="isDriver"
                          />{" "}
                          Is driver
                        </Label>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: 2,
                          right: 0,
                          zIndex: 999,
                        }}
                        onClick={() =>
                          setEmployeeEnabled({
                            ...employeeEnabled,
                            [employeeCrew.employee.id]: false,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="cursor-pointer text-danger bg-white"
                        />
                      </div>
                    </div>
                    {startEndTimeSettingEnabled ? (
                      <>
                        <div
                          className="d-flex align-items-start flex-column py-2 col-12 px-0"
                          tag="div"
                        >
                          <Label className="d-flex">
                            <small>Start Time</small>
                            <small className="ms-1 text-danger">*</small>
                          </Label>
                          <DebounceInput
                            debounceTimeout={900}
                            className="form-control-redesign px-0"
                            required={true}
                            step="60"
                            placeholder="Start Time"
                            type="time"
                            value={startTime || ""}
                            onChange={(event) => {
                              const mStartTime = moment(
                                event.target.value,
                                "HH:mm"
                              );
                              let endTime;
                              if (travelTime.endTime) {
                                const mEndTime = moment(
                                  moment(travelTime.endTime).format("HH:mm"),
                                  "HH:mm"
                                );
                                endTime = moment(selectedDate)
                                  .set("hours", mEndTime.get("hours"))
                                  .set("minutes", mEndTime.get("minutes"));
                                if (mEndTime.isBefore(mStartTime)) {
                                  endTime.add(1, "day");
                                }
                                endTime = endTime.toISOString();
                              }
                              const startTime = moment(selectedDate)
                                .set("hours", mStartTime.get("hours"))
                                .set("minutes", mStartTime.get("minutes"))
                                .toISOString();
                              setTimeToAdd({
                                ...timeToAdd,
                                [employee.id]: {
                                  ...(travelTime || {}),
                                  startTime,
                                  endTime,
                                },
                              });
                            }}
                          />
                        </div>
                        <div
                          className="d-flex align-items-start flex-column py-2 col-12 px-0"
                          tag="div"
                        >
                          <Label className="d-flex">
                            <small>End Time</small>
                            <small className="ms-1 text-danger">*</small>
                          </Label>
                          <DebounceInput
                            debounceTimeout={900}
                            className="form-control-redesign px-0"
                            required={true}
                            step="60"
                            placeholder="End Time"
                            type="time"
                            value={endTime || ""}
                            onChange={(event) => {
                              const mEndTime = moment(
                                event.target.value,
                                "HH:mm"
                              );
                              const endTime = moment(selectedDate)
                                .set("hours", mEndTime.get("hours"))
                                .set("minutes", mEndTime.get("minutes"));
                              if (travelTime.startTime) {
                                const mStartTime = moment(
                                  moment(travelTime.startTime).format("HH:mm"),
                                  "HH:mm"
                                );
                                if (mEndTime.isBefore(mStartTime)) {
                                  endTime.add(1, "day");
                                }
                              }
                              setTimeToAdd({
                                ...timeToAdd,
                                [employee.id]: {
                                  ...(travelTime || {}),
                                  endTime: endTime.toISOString(),
                                },
                              });
                            }}
                          />
                        </div>
                        {startTime && endTime ? (
                          <div
                            className="d-flex align-items-start flex-column py-2 col-12 px-0"
                            tag="div"
                          >
                            <Label>
                              <small>Hours</small>
                              <small className="ms-1 text-danger">*</small>
                            </Label>
                            <DebounceInput
                              debounceTimeout={900}
                              className="form-control-redesign"
                              placeholder="Hours..."
                              value={hours}
                              disabled={true}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div
                        className="d-flex align-items-start flex-column py-2 col-12 px-0"
                        tag="div"
                      >
                        <Label className="d-flex">
                          <small>Hours</small>
                          <small className="ms-1 text-danger">*</small>
                        </Label>
                        <DebounceInput
                          debounceTimeout={900}
                          className="form-control-redesign px-0"
                          required={true}
                          max={24}
                          min={0.1}
                          step={0.1}
                          placeholder="Hours"
                          type="number"
                          value={travelTime || ""}
                          onChange={(evt) => onHoursChange(evt, employee.id)}
                        />
                      </div>
                    )}
                    <div
                      className="d-flex align-items-start flex-column py-2 col-12 px-0"
                      tag="div"
                    >
                      <Label>
                        <small>Mileage</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <DebounceInput
                        debounceTimeout={900}
                        className="form-control-redesign px-0"
                        required={true}
                        min={0}
                        max={999999999}
                        step={1}
                        placeholder="Mileage"
                        type="number"
                        value={mileageToAdd[employeeCrew.employee.id] || ""}
                        onChange={(evt) =>
                          onMileageChange(evt, employeeCrew.employee.id)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      setEmployeeEnabled({
                        ...employeeEnabled,
                        [employeeCrew.employee.id]: true,
                      })
                    }
                  >
                    Add Time
                  </Button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CrewMembers;
