import React from "react";

import { useGetEmployees } from "./api/Employees.hooks";
import { isCrewLeadRole } from "./helpers/employeeRole";
import { useGetEmployeeRoles } from "./api/EmployeeRoles.hooks";
import { data, sharedHelper } from "@crewos/shared";

import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

import { Route, Outlet } from "react-router-dom";

import Employees from "./pages/Employees";

import { SettingRoutes, settingsSidebarRoutes } from "./routes/SettingsRoutes";

const { useAuth, SIDEBAR_ACTIONS } = data;

const EMPLOYEES = "EMPLOYEES";

// Routes
const routes = [
  {
    path: "/employees",
    name: "Employees",
    package: "employees",
    icon: faUserFriends,
    sidebar: true,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    component: Employees,
  },
];

const Wrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "employees"
  );

  return !packageEnabled || !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="/employees" element={<Wrapper />}>
      {routes.flatMap((route, index) =>
        route.entries
          ? route.entries.map((entry, index) => (
              <Route
                key={index}
                path={entry.path}
                element={<entry.component />}
              />
            ))
          : [
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />,
            ]
      )}
      {SettingRoutes}
    </Route>
  </React.Fragment>
);

const setEmployeeSidebarRoutes = ({ setSidebarContext, userData }) => {
  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "employees");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: EMPLOYEES, order: 6 },
    });
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

export {
  useGetEmployeeRoles,
  useGetEmployees,
  Routes as EmployeeRoutes,
  setEmployeeSidebarRoutes,
  isCrewLeadRole,
};
