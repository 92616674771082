import React, { useState } from "react";

import { Collapse } from "reactstrap";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { sharedHelper, data } from "@crewos/shared";
import { crewHelper } from "../helpers/crewHelper";

const { useAuth } = data;

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const CrewWorkDayCrew = ({ children, crew }) => {
  const [authContext] = useAuth();
  const [isOpen, setIsOpen] = useState(true);

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const crewLead = crew.employeeCrews
    .filter((ec) =>
      crewHelper.isEmployeeCrewActiveOnDate(employeeCrewPastDatesEnabled, ec)
    )
    .find((ec) => ec.isLead);

  return (
    <div className="w-100 px-0" key={crew.id}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer mb-3 py-3 px-3 d-flex justify-content-between align-items-center bg-white col-12 border-radius-default"
      >
        <div className="d-flex align-items-center bg-lighter text-success px-4 py-2 rounded fw-bold box-shadow-darker">
          {crewLead ? sharedHelper.getName(crewLead.employee) : "No Lead"}
        </div>
        <div className="fw-bold mb-0 col-6 text-center text-primary"></div>
        <div className="d-flex align-items-center justify-content-end col-3 px-0">
          <small className="text-primary fw-bold">
            View {isOpen ? "less" : "more"}
          </small>
          <FontAwesomeIcon
            className={`ms-2 ${isOpen ? "" : "rotate"}`}
            icon={faChevronDown}
          />
        </div>
      </div>
      <Collapse isOpen={Boolean(isOpen)} className="px-3">
        {children}
      </Collapse>
    </div>
  );
};

export default CrewWorkDayCrew;
