import React from "react";
import { Route } from "react-router-dom";
import PackageWrapper from "../components/PackageWrapper";

const getRoutes = ({ route, index }) => {
  const { entries, component, path } = route;
  const Component = component || PackageWrapper;

  return (
    <Route path={path} element={<Component />} key={index}>
      {entries &&
        entries.map((entry, index) => getRoutes({ route: entry, index }))}
    </Route>
  );
};

const createRoutes = (routes) => {
  return routes.map((route, index) => getRoutes({ route, index }));
};

export { getRoutes, createRoutes };
