import React, { useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import {
  faCheck,
  faBan,
  faClock,
  faTrash,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components, sharedHelper } from "@crewos/shared";

import ExpenseModal from "./ExpenseModal";

import { useDeleteExpense } from "../api/Expenses.hooks";

import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";

const { ConfirmationModal, TooltipItem } = components;

const DECLINED = "declined";
const APPROVED = "approved";

const Expense = ({ expense, readOnly = false, onRefresh }) => {
  const [, setCrewWorkDaysContext] = useCrewWorkDays();

  const [updateExpense, setUpdateExpense] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteExpenseData, deleteExpense } = useDeleteExpense();

  useEffect(() => {
    if (deleteExpenseData) {
      sharedHelper.successToast(`Expense deleted`);
      onRefresh();
    }
  }, [deleteExpenseData, onRefresh]);

  const onEdit = (expense, readOnly) =>
    setUpdateExpense({ ...expense, readOnly });

  const onDeleteExpense = () =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal();
        deleteExpense(expense.id);
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Expense",
      body: `Are you sure you want to delete this expense?`,
    });

  return (
    <div key={expense.id} className="col-12">
      <Card className="mb-0 box-shadow-darker">
        <CardBody>
          <div className="d-flex align-items-start justify-content-between">
            <h4 className="currency">
              {sharedHelper.formatCurrency(expense.amount)}
            </h4>
            <div className="d-flex align-items-center">
              <div
                style={{
                  zIndex: 999,
                }}
                className="cursor-pointer"
                onClick={() => onEdit(expense, true)}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faUpRightAndDownLeftFromCenter}
                  className="text-primary-light"
                />
              </div>
              <TooltipItem
                id="tooltip-expense-status"
                title={sharedHelper.capitalize(expense.status)}
              >
                {expense.status === APPROVED ? (
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheck}
                    className="ms-3 text-success"
                  />
                ) : expense.status === DECLINED ? (
                  <FontAwesomeIcon
                    size="sm"
                    icon={faBan}
                    className="ms-3 text-danger"
                  />
                ) : (
                  <FontAwesomeIcon icon={faClock} className="ms-3 text-muted" />
                )}
              </TooltipItem>
            </div>
          </div>
          <small className="text-muted">
            {expense.expenseType.name} with {expense.paymentType}
          </small>
          {!readOnly ? (
            <div className="mt-3 d-flex">
              <Button
                className="cursor-pointer border-radius-md"
                onClick={() => onDeleteExpense(expense)}
                data-testid="delete-button"
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faTrash}
                  className="text-danger"
                />
              </Button>
              <Button
                color="primary"
                className="cursor-pointer ms-3 flex-grow-1"
                onClick={() => onEdit(expense)}
              >
                Edit
              </Button>
            </div>
          ) : null}
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : updateExpense ? (
        <ExpenseModal
          readOnly={updateExpense.readOnly}
          expense={updateExpense}
          onSubmit={() => {
            setUpdateExpense(false);
            sharedHelper.successToast(`Expenses saved`);
            setCrewWorkDaysContext({
              action: CREW_WORK_DAY_ACTIONS.REFRESH,
            });
          }}
          onClose={() => setUpdateExpense(false)}
        />
      ) : null}
    </div>
  );
};

export default Expense;
