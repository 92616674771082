import React, { useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useCreateAttachment,
  useGetAttachments,
} from "../api/Attachments.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

import Photos from "./Photos";

const { Loader, UploadPhotosModal } = components;

const { useAuth, useWorkOrderDetails } = data;

const CrewWorkDaysPhotos = ({ crewWorkDay }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [attachments, setAttachments] = useState([]);
  const [refresh, setRefresh] = useState();

  const [createModal, setCreateModal] = useState(false);
  const {
    data: getAttachmentsData,
    isLoading: isLoadingGetAttachments,
    get: getAttachments,
  } = useGetAttachments();

  useEffect(() => {
    getAttachments({
      crewWorkDayId: crewWorkDay.id,
    });
  }, [getAttachments, refresh, crewWorkDay]);

  useEffect(() => {
    if (getAttachmentsData) {
      setAttachments(getAttachmentsData);
    }
  }, [getAttachmentsData, setAttachments]);

  const {
    isLoading: isLoadingCreateAttachment,
    mutate: createAttachment,
    data: createAttachmentData,
  } = useCreateAttachment();

  useEffect(() => {
    if (createAttachmentData) {
      sharedHelper.successToast(`Photos uploaded`);
      setCreateModal(false);
      setRefresh((prev) => !prev);
    }
  }, [createAttachmentData]);

  return (
    <>
      <Card className="w-100 mb-3 px-0">
        <CardBody>
          <div className="mb-n1 d-flex align-items-center justify-content-between">
            <h3>Photos</h3>
            <div className="d-flex">
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  setCreateModal(crewWorkDay.id);
                }}
                data-testid="add-button"
              >
                Add
              </Button>
              <Button
                size="sm"
                className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                data-testid="refresh-button"
                onClick={() => setRefresh((prev) => !prev)}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
            </div>
          </div>
          {isLoadingGetAttachments || isLoadingCreateAttachment ? (
            <Loader size="sm" className="my-3" />
          ) : attachments.length ? (
            <Photos
              attachments={attachments}
              onRefresh={() => setRefresh((prev) => !prev)}
            />
          ) : (
            <div className="p-2 small text-muted text-center">No photos</div>
          )}
        </CardBody>
      </Card>
      {createModal ? (
        <UploadPhotosModal
          title={"Add Photos"}
          onClose={() => setCreateModal(false)}
          onSubmit={(data) =>
            createAttachment({
              url: data.attachmentUrl,
              workOrderId: workOrderDetails.workOrder.id,
              description: data.description,
              crewWorkDayId: crewWorkDay.id,
            })
          }
        />
      ) : null}
    </>
  );
};

const CrewWorkDaysPhotosWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(
      authContext.userData?.packages,
      "attachments"
    )
  ) {
    return null;
  }

  return <CrewWorkDaysPhotos {...props} />;
};

export default CrewWorkDaysPhotosWrapper;
