import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useCrewWorkDays } from "@crewos/crews";
import { DebounceInput } from "react-debounce-input";
import Loader from "@crewos/shared/src/components/Loader";
import CrewWorkDay from "./CrewWorkDay";
import { data, components } from "@crewos/shared";
import AssignTemplateWOModal from "./AssignTemplateWOModal";

const { useWorkOrderDetails } = data;
const { WorkOrderDateSelector } = components;

const CrewsInspections = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();
  const [search, setSearch] = useState();
  const [viewPerDay, setViewPerDay] = useState(true);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(0);

  const handleSearch = useCallback(($event) => {
    setSearch($event.target.value);
  }, []);

  const handleViewPerDay = useCallback(
    ($event) => {
      setViewPerDay(!viewPerDay);
    },
    [viewPerDay]
  );

  const handleOpenAssignTemplate = useCallback(() => {
    setShowAssignModal(true);
  }, []);

  const handleAssignTemplate = useCallback(() => {
    setShowAssignModal(false);
    setNeedsUpdate((prev) => prev + 1);
  }, []);

  const crewWorkDay = useMemo(() => {
    return crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate];
  }, [crewWorkDaysContext, workOrderDetails]);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 mb-3 px-0">
          <CardBody className="border-top-left-radius-0">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="mb-0 col-6">Inspections</h2>
              <div className="col-6 d-flex justify-content-end gap-5">
                <div className="d-flex gap-2">
                  <DebounceInput
                    className="search border-0 form-control"
                    maxLength={50}
                    minLength={1}
                    debounceTimeout={900}
                    placeholder="Search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <FormGroup
                  switch
                  className="input-switch ps-5 d-flex align-items-center gap-2 justify-content-center"
                >
                  <Input
                    type="switch"
                    role="switch"
                    id="view-per-day"
                    name="view-per-day"
                    checked={viewPerDay}
                    onChange={handleViewPerDay}
                    className="cursor-pointer"
                  />
                  <Label
                    for="view-per-day"
                    className="cursor-pointer pt-1 mb-0 d-flex text-muted small"
                  >
                    View per day
                  </Label>
                </FormGroup>
                <Button color="primary" onClick={handleOpenAssignTemplate}>
                  Assign Template
                </Button>
              </div>
            </div>
            {viewPerDay && (
              <WorkOrderDateSelector
                crewWorkDays={crewWorkDaysContext.crewWorkDays}
              />
            )}
          </CardBody>
        </Card>
        {workOrderDetails.loading && (
          <Card className="w-100">
            <CardBody className="py-3">
              <Loader size="sm" />
            </CardBody>
          </Card>
        )}
        {!crewWorkDay ? (
          <Card className="w-100 px-0">
            <CardBody className="py-3">
              <div className="text-center text-muted small">
                No data to display
              </div>
            </CardBody>
          </Card>
        ) : (
          crewWorkDay.map((crewWorkDay) => (
            <CrewWorkDay
              key={crewWorkDay.id}
              workOrder={workOrderDetails.workOrder}
              crewWorkDay={crewWorkDay}
              isViewPerDay={viewPerDay}
              needsUpdate={needsUpdate}
              search={search}
            />
          ))
        )}
      </Row>
      {showAssignModal && (
        <AssignTemplateWOModal
          isOpen={showAssignModal}
          workOrder={workOrderDetails.workOrder}
          onAssign={handleAssignTemplate}
          onClose={() => setShowAssignModal(false)}
        />
      )}
    </Col>
  );
};

export default CrewsInspections;
