import React, { useCallback, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import Select from "react-select";
import InputEditable from "./InputEditable";
import Options from "./Options";
import { useInspectionTemplate, INSPECTION_STATUS } from "../providers/inspectionTemplateProvider";
import GuidanceForm from "./GuidanceForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const TEXT_FIELD = "TEXT_FIELD";
const DATE_FIELD = "DATE_FIELD";
const MULTIPLE_CHOICE_FIELD = "MULTIPLE_CHOICE_FIELD";

const QuestionTypePreview = ({ question, getOptions }) => {
  switch (question.questionType?.slug) {
    case TEXT_FIELD:
      return (
        <textarea
          disabled
          rows={4}
          type="text"
          maxLength="255"
          className="form-control"
          placeholder="This is a text input example, short or long."
        />
      );
    case DATE_FIELD:
      return <input type="date" disabled className="form-control" />;
    case MULTIPLE_CHOICE_FIELD:
      const options = getOptions(question.id);
      return <Options options={options || []} questionId={question.id} />;
    default:
      return null;
  }
};

const QuestionItem = ({ question }) => {
  const {
    updateQuestion,
    getOptions,
    state: { questionTypes },
    copyQuestion,
    removeQuestion,
    getErrors,
    deleteGuidance,
    addGuidance,
  } = useInspectionTemplate();

  const hasGuidance = useMemo(() => {
    return !!question.guidance && question.guidance?.status !== INSPECTION_STATUS.DELETE;
  }, [question]);

  const handleUpdateName = useCallback(
    (question, $event) => {
      const value = $event.target.value;
      updateQuestion({ ...question, content: value });
    },
    [updateQuestion]
  );

  const handleUpdateQuestionType = useCallback(
    (selected) => {
      const questionType = questionTypes.find(
        (type) => type.id === selected.value
      );
      updateQuestion({
        ...question,
        questionType,
        inspectionQuestionTypeId: selected.value,
      });
    },
    [questionTypes, updateQuestion, question]
  );

  const handleUpdateRequired = useCallback(() => {
    updateQuestion({ ...question, isRequired: !question.isRequired });
  }, [question, updateQuestion]);

  const handleUpdateGuidance = useCallback(($event) => {
    if ($event.target.checked) {
      addGuidance(question.id);
    } else {
      deleteGuidance(question.id);
    }
  }, [question, addGuidance, deleteGuidance]);

  const questionTypesSelect = useMemo(() => {
    return questionTypes
      ? questionTypes.map((type) => ({ value: type.id, label: type.name }))
      : [];
  }, [questionTypes]);

  const defaultQuestionType = useMemo(() => {
    return questionTypesSelect.find(
      (type) => type.value === question.questionType.id
    );
  }, [question, questionTypesSelect]);

  return (
    <Card key={question.id} className="w-100 sortable-item bg-white">
      <CardHeader className="py-3 pt-4 pb-0">
        <Row>
          <Col sm={11}>
            <span className="text-muted small">{question.order}. </span>
            <InputEditable
              value={question.content}
              onChange={($event) => handleUpdateName(question, $event)}
              placeholder="Question Name"
              size="medium"
              errors={getErrors(question.id)}
            />
          </Col>
          <Col className="d-flex justify-content-end" sm={1}>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button border-0"
              color="white"
              onClick={() => copyQuestion(question)}
              aria-label="Copy Question"
            >
              <FontAwesomeIcon icon={faCopy} size="xl" />
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={6}>
            <QuestionTypePreview question={question} getOptions={getOptions} />
            {getErrors(question.id)?.map((error) => (
              <small key={error} className="fst-italic text-danger d-block">{error}</small>
            ))}
          </Col>
          <Col xs={5} className="d-flex justify-content-center">
            <div className="gap-3 d-flex flex-column w-50">
              <Select
                options={questionTypesSelect}
                defaultValue={defaultQuestionType}
                onChange={handleUpdateQuestionType}
                styles={style}
                classNamePrefix="react-select"
                className="text-start bg-white px-0 react-select"
                placeholder="Select type.."
                data-testid={`question-type-${question.id}`}
              />
              <FormGroup
                switch
                className="input-switch ps-5 d-flex align-items-center gap-2"
              >
                <Input
                  type="switch"
                  role="switch"
                  id={`is-required-${question.id}`}
                  name={`is-required-${question.id}`}
                  checked={!!question.isRequired}
                  onChange={handleUpdateRequired}
                  className="cursor-pointer"
                />
                <Label
                  for={`is-required-${question.id}`}
                  className="cursor-pointer pt-1 mb-0 d-flex text-muted small"
                >
                  Is Required
                </Label>
              </FormGroup>
              <FormGroup
                switch
                className="input-switch ps-5 d-flex align-items-center gap-2"
              >
                <Input
                  type="switch"
                  role="switch"
                  id={`add-guidance-${question.id}`}
                  name={`add-guidance-${question.id}`}
                  checked={!!hasGuidance}
                  onChange={handleUpdateGuidance}
                  className="cursor-pointer"
                />
                <Label
                  for={`add-guidance-${question.id}`}
                  className="cursor-pointer pt-1 mb-0 d-flex text-muted small"
                >
                  Add guidance
                </Label>
              </FormGroup>
            </div>
          </Col>
          <Col xs={1} className="d-flex align-items-end justify-content-end">
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button text-danger border-0"
              color="white"
              onClick={() => removeQuestion(question)}
              aria-label="Delete Question"
            >
              <FontAwesomeIcon icon={faTrash} size="xl" />
            </Button>
          </Col>
        </Row>
        {hasGuidance && <GuidanceForm guidance={question.guidance || {}} questionId={question.id} />}
      </CardBody>
    </Card>
  );
};

export default QuestionItem;
