import React, {useCallback, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Collapse} from "reactstrap";
import QuestionDetail from "./QuestionDetail";

const GroupDetail = ({ group }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapse = useCallback(($event) => {
    $event.preventDefault();
    setIsOpen(prev => !prev);
  }, []);

  const totalAnsweredQuestions = useMemo(() => {
    return group.questions.reduce((acc, question) => {
      if (question.answers.length > 0) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [group]);

  return (
    <div className={`m-2 border-radius-md border-light-blue ${isOpen ? "border border-1" : ""}`}>
      <div className={`cursor-pointer p-2 d-flex justify-content-between align-items-center`} onClick={handleCollapse}>
        <div data-testid="group-title">
          <span className="fw-semibold">{group.name} - {totalAnsweredQuestions}/</span>{group.questions.length}
        </div>
        <div>
          <FontAwesomeIcon
            className={`ms-2 rotate ${isOpen ? "rotate-90" : ""}`}
            icon={faChevronRight}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        {group.questions.map((question) => (
          <QuestionDetail key={question.id} question={question} />
        ))}
      </Collapse>
    </div>
  );
};

export default GroupDetail;
