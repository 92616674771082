import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import moment from "moment";

import { useGetAllWorkTimeTypes } from "@crewos/worktimes";

import {
  QueryBuilder,
  defaultOperators,
  toFullOption,
  formatQuery,
} from "react-querybuilder";

import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";

import "react-querybuilder/dist/query-builder.css";
import "bootstrap-icons/font/bootstrap-icons.scss";

const EXCLUDE_OPERATORS = [
  "contains",
  "beginsWith",
  "endsWith",
  "doesNotContain",
  "doesNotBeginWith",
  "doesNotEndWith",
  "null",
  "notNull",
];
const MAX_TRAVEL_ENTRIES = 10;
const OPERATORS = defaultOperators.filter(
  (op) => !EXCLUDE_OPERATORS.includes(op.value)
);

const { DatesSelector, CustomCheckboxWithLabel } = components;

const validator = (r) => !!r.value;

const EditPerDiemRulesModal = ({ defaultPerDiemRule, onClose, onSubmit }) => {
  const isEdit = defaultPerDiemRule && typeof defaultPerDiemRule === "object";

  const [fields, setFields] = useState();

  const [perDiemRule, setPerDiemRule] = useState(
    isEdit ? defaultPerDiemRule.query : null
  );
  const [workTimeTypes, setWorkTimeTypes] = useState([]);
  const [travelEntries, setTravelEntries] = useState(
    isEdit ? defaultPerDiemRule.travelEntries : 1
  );

  const [dates, setDates] = useState(
    isEdit
      ? {
        startDate:
          defaultPerDiemRule.startDate || moment().format("YYYY-MM-DD"),
        endDate: defaultPerDiemRule.endDate || moment().format("YYYY-MM-DD"),
      }
      : {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      }
  );

  const [name, setName] = useState(isEdit ? defaultPerDiemRule.name : "");

  const [openDates, setOpenDates] = useState(
    isEdit
      ? !defaultPerDiemRule.startDate || !defaultPerDiemRule.endDate
      : false
  );

  const [precedence, setPrecedence] = useState(
    isEdit ? defaultPerDiemRule.precedence : ""
  );

  const [perDiemEarned, setPerDiemEarned] = useState(
    isEdit ? defaultPerDiemRule.amount : ""
  );

  const { get: getWorkTimeTypes, data: workTimeTypesData } =
    useGetAllWorkTimeTypes();

  useEffect(() => {
    getWorkTimeTypes();
  }, [getWorkTimeTypes]);

  useEffect(() => {
    if (workTimeTypesData) {
      setWorkTimeTypes(workTimeTypesData);
    }
  }, [workTimeTypesData]);

  useEffect(() => {
    const travelFields = Array.from(
      Array(parseInt(travelEntries)).keys()
    ).flatMap((index) => [
      {
        name: `travel${index + 1}Hours`,
        label: `Travel ${index + 1} Hours`,
        placeholder: "Enter hours",
        operators: OPERATORS,
        validator,
      },
      {
        name: `travel${index + 1}Miles`,
        label: `Travel ${index + 1} Miles`,
        placeholder: "Enter miles",
        operators: OPERATORS,
        validator,
      },
      {
        name: `travel${index + 1}Driver`,
        label: `Is Travel ${index + 1} Driver`,
        valueEditorType: "checkbox",
        operators: OPERATORS.filter((op) => op.value === "="),
        defaultValue: false,
      },
    ]);
    const fields = [
      ...travelFields,
      ...workTimeTypes.map((workTimeType) => ({
        name: `${workTimeType.shortName}WorkHours`,
        label: `${workTimeType.name} Work Hours`,
        placeholder: "Enter hours",
        operators: OPERATORS,
        validator,
      })),
    ];
    setFields(fields.map((o) => toFullOption(o)));
  }, [workTimeTypes, travelEntries, perDiemRule]);

  const doSubmit = (evt) => {
    evt.preventDefault();
    if (!openDates && !dates.startDate) {
      return sharedHelper.warningToast(
        "Start date is required when open dates is disabled"
      );
    }
    if (!openDates && !dates.endDate) {
      return sharedHelper.warningToast(
        "End date is required when open dates is disabled"
      );
    }
    if (perDiemRule.rules.find((rule) => rule.value === "")) {
      return sharedHelper.warningToast("All values are required");
    }
    onSubmit({
      name,
      travelEntries,
      query: perDiemRule,
      condition: formatQuery(perDiemRule, "cel"),
      startDate: openDates ? null : dates.startDate,
      endDate: openDates ? null : dates.endDate,
      precedence,
      amount: perDiemEarned,
      index: defaultPerDiemRule.index,
    });
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader
        className="d-flex justify-content-between"
        toggle={onClose}
      >
        {isEdit ? "Edit" : "Create"} Per Diem Rule
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Rule Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="50"
              type="text"
              min={0}
              placeholder="Enter the name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>Precedence</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              type="number"
              min={0}
              placeholder="Enter the precedence"
              value={precedence}
              onChange={(e) => setPrecedence(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>Per Diem Earned</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              type="number"
              min={0}
              placeholder="Enter the per diem earned"
              value={perDiemEarned}
              onChange={(e) => setPerDiemEarned(e.target.value)}
              required
            />
          </FormGroup>
          {!openDates ? (
            <div className="d-flex align-items-center justify-content-end w-100">
              <DatesSelector
                defaultStartDate={dates.startDate}
                defaultEndDate={dates.endDate}
                onSubmit={(startDate, endDate) => {
                  setDates({
                    startDate,
                    endDate,
                  });
                }}
              />
            </div>
          ) : null}
          <FormGroup>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Label>
                <span>Rule</span>
                <span className="text-danger ms-1">*</span>
              </Label>
              <FormGroup noMargin className="d-flex align-items-center small">
                <Label className="flex-shrink-0 mb-0 me-2">
                  <span>Daily TT Entries (Up to)</span>
                </Label>
                <input
                  className="border-0 small text-muted"
                  type="number"
                  min={0}
                  max={MAX_TRAVEL_ENTRIES}
                  placeholder="Enter the TT entries"
                  value={travelEntries}
                  onChange={(e) => setTravelEntries(e.target.value)}
                  required
                />
              </FormGroup>
            </div>
            <QueryBuilderBootstrap>
              <QueryBuilder
                fields={fields}
                query={perDiemRule}
                onQueryChange={setPerDiemRule}
                showCombinatorsBetweenRules
              />
            </QueryBuilderBootstrap>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex align-items-center">
            <Button
              color="secondary"
              onClick={onClose}
              className="me-2 text-dark"
            >
              Discard
            </Button>
            <CustomCheckboxWithLabel
              label="Open Dates"
              className="btn btn-info"
              color="info"
              checked={openDates}
              onClick={() => setOpenDates(!openDates)}
            />
          </div>
          <Button color="primary" type="submit" name="save">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default EditPerDiemRulesModal;
