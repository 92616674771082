import React, { useState, useEffect } from "react";
import {
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselCaption,
  CarouselControl,
  Button,
} from "reactstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faDownload,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { sharedHelper } from "../helpers/sharedHelper";
import { awsApi } from "../api/awsServices";
import Loader from "./Loader";
import ImageContainer from "./ImageContainer";

const MODE_PREVIEW = "MODE_PREVIEW";
const MODE_FULL = "MODE_FULL";

const getUrlFileNameExtension = (url) => {
  const regexFilename = /([^/]*)$/gm;
  const fileNameMatch = regexFilename.exec(url);
  const fileName = fileNameMatch[1];

  const regexFileType = /\.([^.]+)$/gm;

  const fileTypeMatch = regexFileType.exec(url);
  const fileType = fileTypeMatch[1];

  return { fileName, fileType };
};

const ImagesViewer = ({
  showCaption = true,
  showActions = true,
  slide = true,
  items,
  onDelete,
  mode = MODE_FULL,
  width,
  height,
}) => {
  const [loading, setLoading] = useState();
  const [signedUrls, setSignedUrls] = useState({});
  const [localItems] = useState([...items]);

  useEffect(() => {
    const signUrls = async () => {
      setLoading(true);
      const documents = localItems.map((item) => {
        const { fileName, fileType } = getUrlFileNameExtension(item.url);
        return {
          fileName,
          fileType,
          bucket: process.env.REACT_APP_IMAGES_BUCKET_NAME,
          method: "getObject",
        };
      });
      try {
        const signedUrls = await awsApi.signDocuments({
          documents,
        });
        setSignedUrls(signedUrls);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    signUrls();
  }, [localItems]);

  const selectedItemIndex = items.findIndex((item) => item.selected);

  const [selectedIndex, setSelectedIndex] = useState(
    selectedItemIndex !== -1 ? selectedItemIndex : 0
  );

  return loading ? (
    <Loader size="sm" />
  ) : mode === MODE_PREVIEW ? (
    items.map((item, i) => (
      <ImageContainer
        mode={MODE_PREVIEW}
        key={i}
        width={width}
        height={height}
        src={signedUrls[i]?.signedRequest}
        className="border-radius-default cursor-pointer"
      />
    ))
  ) : (
    <Carousel
      activeIndex={selectedIndex}
      next={() => setSelectedIndex((selectedIndex + 1) % items.length)}
      previous={() =>
        setSelectedIndex((selectedIndex - 1 + items.length) % items.length)
      }
      slide={slide}
    >
      <CarouselIndicators
        className="d-none"
        items={items.map((item, i) => ({
          caption: item.title || `Item ${i + 1}`,
          key: i + 1,
          src: signedUrls[i]?.signedRequest,
        }))}
        activeIndex={selectedIndex}
        onClickHandler={(index) => setSelectedIndex(index)}
      />
      {items.map((item, i) => (
        <CarouselItem key={i} className="border-radius-default" slide={slide}>
          {showActions ? (
            <TransformWrapper
              initialScale={1}
              minScale={0.5}
              maxScale={3}
              className="w-100"
            >
              {({ zoomIn, zoomOut }) => (
                <React.Fragment>
                  <div className="tools tools-left m-3 d-flex align-items-center p-1">
                    {onDelete ? (
                      <Button
                        color="none"
                        className="text-primary d-flex align-items-center"
                        onClick={() => {
                          onDelete(item);
                        }}
                        data-testid="delete-button"
                      >
                        <FontAwesomeIcon icon={faTrash} size="sm" />
                      </Button>
                    ) : null}
                    <Button
                      color="none"
                      className="text-primary d-flex align-items-center"
                      data-testid="download-button"
                      onClick={() => {
                        sharedHelper.downloadFile(signedUrls[i]?.signedRequest);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} size="sm" />
                    </Button>
                  </div>
                  <div className="tools tools-right m-3 d-flex align-items-center p-1">
                    <Button
                      color="none"
                      className="text-primary d-flex align-items-center"
                      onClick={() => zoomOut()}
                    >
                      <FontAwesomeIcon icon={faMinus} size="sm" />
                    </Button>
                    <Button
                      color="none"
                      className="text-primary d-flex align-items-center"
                      onClick={() => zoomIn()}
                    >
                      <FontAwesomeIcon icon={faPlus} size="sm" />
                    </Button>
                  </div>
                  <TransformComponent>
                    <ImageContainer
                      width={width}
                      height={height}
                      alt={item.title || `Item ${i + 1}`}
                      src={signedUrls[i]?.signedRequest}
                      className="border-radius-default"
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          ) : (
            <ImageContainer
              width={width}
              height={height}
              alt={item.title || `Item ${i + 1}`}
              src={signedUrls[i]?.signedRequest}
              className="border-radius-default"
            />
          )}
          {showCaption ? (
            <CarouselCaption
              className="p-0"
              captionHeader={item.title || `Item ${i + 1}`}
              captionText={item.subtitle || ""}
            />
          ) : null}
        </CarouselItem>
      ))}
      {items.length > 1 ? (
        <>
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={() =>
              setSelectedIndex(
                (selectedIndex - 1 + items.length) % items.length
              )
            }
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={() =>
              setSelectedIndex((selectedIndex + 1) % items.length)
            }
          />
        </>
      ) : null}
    </Carousel>
  );
};

export default ImagesViewer;
