import React, { useEffect } from "react";

import { data } from "@crewos/shared";

import { Route, Outlet } from "react-router-dom";
import WorkTimeTypes from "../pages/settings/WorkTimeTypes";
import Settings from "../pages/settings/Settings";

const { useAuth, useSidebar, SIDEBAR_ACTIONS } = data;

const PACKAGE = "worktimes";

// Routes
const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Work Time Settings",
    path: ``,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: PACKAGE,
    category: "Work Time",
    component: Settings,
    order: 1,
  },
  {
    sidebar: true,
    icon: null,
    name: "Work Time Types",
    path: `work-time-types`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: PACKAGE,
    category: "Work Time",
    component: WorkTimeTypes,
    order: 2,
  },
];

const Wrapper = () => {
  const [sidebarContext, setSidebarContext] = useSidebar();
  const [authContext] = useAuth();

  useEffect(() => {
    if (
      !sidebarContext.settingsRoutes.find((route) => route.package === PACKAGE)
    ) {
      setSidebarContext({
        action: SIDEBAR_ACTIONS.SET_SETTINGS_ROUTES,
        payload: { routes },
      });
    }
  }, [sidebarContext.settingsRoutes, sidebarContext.routes, setSidebarContext]);

  return !authContext.userData ? null : <Outlet />;
};

const Routes = (
  <React.Fragment>
    <Route path="settings" element={<Wrapper />}>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
    </Route>
  </React.Fragment>
);

const sidebarRoutes = routes.filter((route) => route.sidebar);

export { Routes as SettingRoutes, sidebarRoutes as settingsSidebarRoutes };
