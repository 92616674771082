import React, { useEffect, useState } from "react";

import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
} from "reactstrap";

import { useUpdateSmartReport } from "../api/Reports.hooks";
import { sharedHelper, components } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { CustomCheckbox } = components;

const replaceAESDecrypt = (inputString) => {
  const regex = /CONVERT\(AES_DECRYPT\(([^,]+), '([^']+)'\) USING 'UTF8MB4'\)/g;
  return inputString.replace(regex, "$1");
};

const SmartReportDetailModal = ({ report, isReadOnly, onClose, onSubmit }) => {
  const { data: updateReportData, update: updateReport } =
    useUpdateSmartReport();

  const [columns, setColumns] = useState(report.columns);
  const [showSidebar, setShowSidebar] = useState(report.showSidebar);

  useEffect(() => {
    if (updateReportData) {
      sharedHelper.successToast(`Report updated`);
      onSubmit({ ...report, showSidebar: `${Boolean(showSidebar)}`, columns });
    }
  }, [updateReportData, onSubmit, columns, report, showSidebar]);

  const doSubmit = () => {
    updateReport({ ...report, showSidebar: `${Boolean(showSidebar)}` });
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Smart Report
      </ModalHeader>
      <ModalBody>
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-lighter small">
              <th>Name</th>
              {!isReadOnly ? <th>Prompt</th> : null}
            </tr>
          </thead>
          <tbody className="small">
            <tr>
              <td>{report.name}</td>
              {!isReadOnly ? (
                <td>
                  <i>{report.prompt || "-"}</i>
                </td>
              ) : null}
            </tr>
          </tbody>
        </Table>
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-lighter small">
              <th>Description</th>
              {!isReadOnly ? <th>Sidebar Link</th> : null}
            </tr>
          </thead>
          <tbody className="small">
            <tr>
              <td>{report.description || "-"}</td>
              {!isReadOnly ? (
                <td onClick={() => setShowSidebar(!showSidebar)}>
                  <div className="d-flex">
                    <CustomCheckbox checked={showSidebar} />
                  </div>
                </td>
              ) : null}
            </tr>
          </tbody>
        </Table>
        {!isReadOnly ? (
          <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
            <thead>
              <tr className="bg-lighter small">
                <th>Query</th>
              </tr>
            </thead>
            <tbody className="small">
              <tr>
                <td>
                  <code>{replaceAESDecrypt(report.query)}</code>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : null}

        <ListGroup flush className="col-12 px-0">
          <ListGroupItem
            className="py-2 bg-lighter border-0 border-radius-default fw-bold text-center"
            tag="div"
            key="0"
          >
            Columns
          </ListGroupItem>
          <Table className="col-12 px-0 mb-0 overflow-hidden mt-2" striped>
            <thead>
              <tr className="bg-lighter small">
                <th className="text-start">Label</th>
                <th className="text-center">Accessor</th>
                <th className="text-center">Order</th>
                <th className="text-end">Enabled</th>
              </tr>
            </thead>
            <tbody className="small">
              {columns
                .sort((x, y) => x.order - y.order)
                .map((column) => (
                  <tr>
                    {isReadOnly ? (
                      <td className="text-start">{column.label}</td>
                    ) : (
                      <DebounceInput
                        debounceTimeout={900}
                        placeholder="Enter label..."
                        className="form-control py-1"
                        maxLength="50"
                        type="text"
                        value={column.label}
                        onChange={(e) => {
                          column.label = e.target.value;
                          setColumns([...columns]);
                        }}
                      />
                    )}
                    <td className="text-center">{column.accessor}</td>
                    <td className="text-center">{column.order}</td>
                    {isReadOnly ? (
                      <td className="text-end">
                        {column.enabled ? "Yes" : "No"}
                      </td>
                    ) : (
                      <div
                        className="min-width-50 d-flex justify-content-end"
                        onClick={() => {
                          column.enabled = !column.enabled;
                          setColumns([...columns]);
                        }}
                      >
                        <CustomCheckbox checked={column.enabled} />
                      </div>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="primary" onClick={doSubmit}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SmartReportDetailModal;
