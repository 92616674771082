import React from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { data, sharedHelper } from "@crewos/shared";

import WorkOrderJobDetails from "./WorkOrderJobDetails";

import {
  WorkOrderCrews,
  WorkOrderCrewsTimes,
  WorkOrderCrewsEquipments,
  WorkOrderCrewsExpenses,
  WorkOrderCrewsPerDiems,
  WorkOrderCrewsInspections,
} from "@crewos/crews";

import { SmartReports } from "@crewos/reports";
import WorkOrderJobDescription from "./WorkOrderJobDescription";

const { useAuth, useWorkOrderDetails, WORK_ORDER_DETAILS_ACTIONS } = data;

const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";
const JOB_DESCRIPTION_DETAILS_TAB = "JOB_DESCRIPTION_DETAILS_TAB";
const CREW_TAB = "CREW_TAB";
const TIME_TAB = "TIME_TAB";
const EXPENSES_TAB = "EXPENSES_TAB";
const PER_DIEMS_TAB = "PER_DIEMS_TAB";
const EQUIPMENTS_TAB = "EQUIPMENTS_TAB";
const REPORTS_TAB = "REPORTS_TAB";
const SMART_REPORTS_TAB = "SMART_REPORTS_TAB";
const INSPECTIONS_TAB = "INSPECTIONS_TAB";

const SINGLE_CREW = "SINGLE_CREW";
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const CREWS_PACKAGE = "crews";

const WorkOrderDetailsTabs = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const reports = authContext.userData.smartReports.filter(
    (report) => report.context === "WORKORDER"
  );

  const isSingleCrewEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    SINGLE_CREW
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const toggle = (tab) => {
    if (workOrderDetails.activeTab !== tab) {
      setWorkOrderDetails({
        action: WORK_ORDER_DETAILS_ACTIONS.CHANGE_TAB,
        payload: {
          tab,
        },
      });
    }
  };

  return (
    <div className="tab tab-vertical h-100">
      <Nav tabs className="cursor-pointer">
        <NavItem>
          <NavLink
            className={classnames({
              "active fw-bold": workOrderDetails.activeTab === JOB_DETAILS_TAB,
            })}
            onClick={() => toggle(JOB_DETAILS_TAB)}
          >
            Work Performed
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              "active fw-bold":
                workOrderDetails.activeTab === JOB_DESCRIPTION_DETAILS_TAB,
            })}
            onClick={() => toggle(JOB_DESCRIPTION_DETAILS_TAB)}
          >
            Job Description
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              "active fw-bold": workOrderDetails.activeTab === CREW_TAB,
            })}
            onClick={() => toggle(CREW_TAB)}
          >
            {directAssignationEnabled
              ? "Assignments"
              : isSingleCrewEnabled
              ? "Crew"
              : "Crews"}
          </NavLink>
        </NavItem>
        {sharedHelper.isPackageEnabled(authContext.userData?.packages, [
          "worktimes",
          "traveltimes",
        ]) ? (
          <NavItem>
            <NavLink
              className={classnames({
                "active fw-bold": workOrderDetails.activeTab === TIME_TAB,
              })}
              onClick={() => toggle(TIME_TAB)}
            >
              Time
            </NavLink>
          </NavItem>
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "expenses"
        ) ? (
          <NavItem>
            <NavLink
              className={classnames({
                "active fw-bold": workOrderDetails.activeTab === EXPENSES_TAB,
              })}
              onClick={() => toggle(EXPENSES_TAB)}
            >
              Expenses
            </NavLink>
          </NavItem>
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "perdiems"
        ) ? (
          <NavItem>
            <NavLink
              className={classnames({
                "active fw-bold": workOrderDetails.activeTab === PER_DIEMS_TAB,
              })}
              onClick={() => toggle(PER_DIEMS_TAB)}
            >
              Per Diem
            </NavLink>
          </NavItem>
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "equipments"
        ) ? (
          <NavItem>
            <NavLink
              className={classnames({
                "active fw-bold": workOrderDetails.activeTab === EQUIPMENTS_TAB,
              })}
              onClick={() => toggle(EQUIPMENTS_TAB)}
            >
              Equipments
            </NavLink>
          </NavItem>
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "inspections"
        ) && (
          <NavItem>
            <NavLink
              className={classnames({
                "active fw-bold":
                  workOrderDetails.activeTab === INSPECTIONS_TAB,
              })}
              onClick={() => toggle(INSPECTIONS_TAB)}
            >
              Inspections
            </NavLink>
          </NavItem>
        )}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "reports"
        ) ? (
          <NavItem>
            <NavLink
              className={classnames({
                "active fw-bold":
                  workOrderDetails.activeTab === SMART_REPORTS_TAB,
              })}
              onClick={() => toggle(SMART_REPORTS_TAB)}
            >
              Reports
            </NavLink>
          </NavItem>
        ) : null}
        {reports.map((report) => (
          <NavItem key={report.id}>
            <NavLink
              className={classnames({
                "active fw-bold": workOrderDetails.activeTab === report.id,
              })}
              onClick={() => toggle(report.id)}
            >
              {report.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent
        activeTab={workOrderDetails.activeTab}
        className="h-100 p-0 box-shadow-none"
      >
        <TabPane className="bg-lighter" tabId={JOB_DETAILS_TAB}>
          {workOrderDetails.activeTab === JOB_DETAILS_TAB ? (
            <WorkOrderJobDetails />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={JOB_DESCRIPTION_DETAILS_TAB}>
          {workOrderDetails.activeTab === JOB_DESCRIPTION_DETAILS_TAB ? (
            <WorkOrderJobDescription />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={CREW_TAB}>
          {workOrderDetails.activeTab === CREW_TAB ? <WorkOrderCrews /> : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={TIME_TAB}>
          {workOrderDetails.activeTab === TIME_TAB ? (
            <WorkOrderCrewsTimes />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={EXPENSES_TAB}>
          {workOrderDetails.activeTab === EXPENSES_TAB ? (
            <WorkOrderCrewsExpenses />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={PER_DIEMS_TAB}>
          {workOrderDetails.activeTab === PER_DIEMS_TAB ? (
            <WorkOrderCrewsPerDiems />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={EQUIPMENTS_TAB}>
          {workOrderDetails.activeTab === EQUIPMENTS_TAB ? (
            <WorkOrderCrewsEquipments />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={SMART_REPORTS_TAB}>
          {workOrderDetails.activeTab === SMART_REPORTS_TAB ? (
            <SmartReports workOrderId={workOrderDetails.workOrder.id} />
          ) : null}
        </TabPane>
        <TabPane className="bg-lighter" tabId={INSPECTIONS_TAB}>
          {workOrderDetails.activeTab === INSPECTIONS_TAB && (
            <WorkOrderCrewsInspections />
          )}
        </TabPane>
        <TabPane className="bg-lighter" tabId={REPORTS_TAB}>
          {workOrderDetails.activeTab === REPORTS_TAB ? null : null}
        </TabPane>
        {reports.map((report) => (
          <TabPane key={report.id} className="bg-lighter" tabId={report.id}>
            {workOrderDetails.activeTab === report.id && (
              <SmartReports
                workOrderId={workOrderDetails.workOrder.id}
                reportId={report.id}
              />
            )}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default WorkOrderDetailsTabs;
