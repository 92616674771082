import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "reactstrap";
import InputEditable from "./InputEditable";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";

const OptionItem = ({ option }) => {
  const { updateOption, removeOption } = useInspectionTemplate();

  const handleUpdateContent = useCallback(
    ($event) => {
      const value = $event.target.value;
      updateOption({ ...option, content: value });
    },
    [updateOption, option]
  );

  const handleRemove = useCallback(() => {
    removeOption(option);
  }, [removeOption, option]);

  return (
    <Row className="option-item align-items-center mb-2">
      <Col md={1}>
        <FontAwesomeIcon icon={faCircle} />
      </Col>
      <Col md={5} className="justify-content-start">
        <InputEditable
          value={option.content}
          onChange={handleUpdateContent}
          placeholder="Type an option"
          size="small"
        />
      </Col>
      <Col md={1}>
        <Button
          size="sm"
          className="rounded-circle d-flex custom-rounded-button remove-btn border-0 font-size-1 py-1 px-2"
          color="white"
          onClick={handleRemove}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </Col>
    </Row>
  );
};

export default OptionItem;
